<home-banner class="mb-l"></home-banner>
<div class="mt-80"></div>

<div class="du-flex-column gap-m">
  <pull-to-refresh [topSpacer]="'xl'" [gapSpacer]="'xs'" *ngIf="initLoad" (callback)="sessionStorageService.triggerSyncData(); syncData()"></pull-to-refresh>

  <div id="pull-to-refresh-container" class="du-flex-column gap-m" *ngFor="let section of homeItems">
    <span class="du-flex-justify">
      <span class="color-title font-20 font-600">{{ section.label | translate }}</span>
      <span class="color-blue du-flex-center font-xl du-icon-info" *ngIf="section.tutorial"></span>
    </span>

    <div class="du-flex-column gap-xs p-s bg-white b-radius-m" *ngFor="let block of section.children">
      <span class="du-flex-justify gap-m" (click)="navItemCallback(block)">
        <span class="du-flex-align gap-xs">
          <span class="du-flex-center font-xl du-icon-clock" [ngClass]="'du-icon-'+block.icon"></span>
          <span>{{ block.label | translate }}</span>
        </span>
        <div class="du-flex-align gap-xl">
          <span class="du-flex-center pt-xxs pb-xxs pl-s pr-s bg-orange b-radius color-white roboto" *ngIf="getCounter(block.useCase)">{{ getCounterHandler(block.useCase) }}</span>
          <span class="du-flex-center color-blue font-xl du-icon-arrow-drop-down" [class.opacity-0]="!block.children"></span>
        </div>
      </span>
      <div class="du-flex-column" @collapse *ngIf="block.children && block.collapse">
        <div class="du-flex-column overflow gap-xs">
          <div class="du-flex-justify gap-m p-s b-radius" [ngClass]="'bg-'+(item.bgColor ? item.bgColor : 'grey')" *ngFor="let item of block.children" (click)="navItemCallback(item)">
            <div class="du-flex-align-start gap-xs">
              <span class="du-flex-center font-xl du-list-filled" [ngClass]="'du-icon-'+item.leftIcon"></span>
              <div class="du-flex-column gap-xs">
                <span class="pt-xxs">{{ item.title | translate }}</span>
                <span class="font-s font-300" *ngIf="item.subtitle">{{ item.subtitle | translate }}</span>
              </div>
            </div>
            <div class="du-flex-align gap-s">
              <span *ngIf="getCounter(item.useCase)" class="du-flex-center pt-xxs pb-xxs pl-s pr-s bg-orange b-radius color-white roboto" >{{ getCounterHandler(item.useCase) }}</span>
              <span *ngIf="item.rightIcon"           class="du-flex-center color-blue font-xl"  [ngClass]="'du-icon-'+item.rightIcon"></span>
              <span *ngIf="item.rightLink"           class="mta font-s color-blue">{{ item.rightLink | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--
  <div class="du-flex-column gap-m" [tutorialStep]="tutorialStep" [tutorialNumber]="1" [tutorialText]="'theseAreFunctions'            | translate">
  <div class="du-flex-column gap-m" [tutorialStep]="tutorialStep" [tutorialNumber]="2" [tutorialText]="'useThisFeatureToMergeDailies' | translate">
  <div class="du-flex-column gap-s" [tutorialStep]="tutorialStep" [tutorialNumber]="3" [tutorialText]="'thisIsOverviewSection'        | translate">
-->
