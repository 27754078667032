<mileage-overview-item [mileage]="mileage" [extended]="true" (close)="close()"></mileage-overview-item>

<div class="du-flex-column gap-s bt-grey pb-xl">
  <div class="du-flex-column gap-xs" *ngFor="let workDay of mileage.workDays">
    <div class="du-flex-justify gap-m bg-grey pt-xs pb-xs pl-m pr-m bold">
      <span>{{ workDay.date       | date: 'EEEE, dd MMMM YYYY' }}</span>
      <span>{{ workDay.amountOfKm | distanceFormat             }} Km</span>
    </div>
    <div class="du-flex-column gap-xxs pl-m pr-m">
      <span class="font-s" translate="placeOfDeparture"></span>
      <span>{{ workDay.externalEmployeeAddress }}</span>
    </div>
    <div class="du-flex-column gap-xxs  pl-m pr-m">
      <span class="font-s" translate="destination"></span>
      <span>{{ workDay.assignmentAddress }}</span>
    </div>
  </div>
</div>
