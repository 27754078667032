import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { NotificationService, SessionStorageService, VacationRequestsService } from '@shared/services';
import { VacationRequestLocal } from '@shared/factories';
import { slideUp              } from '@shared/animations';

@Component({
  selector:      'vacation-invite',
  templateUrl: './vacation-invite.component.html',
  host: { class: 'height-max-700-auto height-full absolute bottom-0 left-0 right-0 du-flex-column btl-radius btr-radius absolute bg-white'},
  animations: [ slideUp ]
})
export class VacationInviteComponent implements OnInit, OnDestroy {
  @Input()  vacation: VacationRequestLocal;
  @Output() callback = new EventEmitter<VacationRequestLocal>;
  @HostBinding('@slideUp') get slideIn() { return 'open'; }
  private readonly COMPONENT_NAME: string = 'VacationInvite';
  constructor(
    private vacationRequestsService: VacationRequestsService,
    private sessionStorageService:   SessionStorageService,
    private notificationService:     NotificationService
  ) {}

  ngOnInit():    void { this.sessionStorageService.pushOverflowStack(this.COMPONENT_NAME);   }
  ngOnDestroy(): void { this.sessionStorageService.popOverflowStack(this.COMPONENT_NAME);    }
  close():       void { this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME); }

  updateVacationInvite(review: string): void {
    this.notificationService.wait();
    this.vacationRequestsService.updateVacationInvite(this.vacation.id, review).subscribe(
      res => {
        this.sessionStorageService.setChip({
          message: 'Merry Christmas, ya filthy animal',
          bgColor: 'green'
        });
        this.callback.emit();
        this.close();
        this.notificationService.close();
      },
      err => this.notificationService.alert(err)
    );
  }

}
