export const ar = {
  field: 'states',
  tabs: [
    { icon: 'circle-tick',  value: 'approved', color: 'green'  },
    { icon: 'circle-more',  value: 'open',     color: 'orange' },
    { icon: 'circle-cross', value: 'rejected', color: 'red'    }
  ]
};

export const mm = {
  field: 'states',
  tabs: [
    { icon: 'circle-tick',  value: 'approved', color: 'green'  },
    { icon: 'circle-more',  value: 'open',     color: 'orange' },
    { icon: 'circle-cross', value: 'rejected', color: 'red'    }
  ]
};

export const vr = {
  field: 'approval_state',
  tabs: [
    { icon: 'circle-tick',  value: 'approved', color: 'green'  },
    { icon: 'circle-more',  value: 'awaiting', color: 'orange' },
    { icon: 'circle-cross', value: 'rejected', color: 'red'    }
  ]
};

export const pd = {
  field: 'read',
  tabs: [
    { icon: 'circle-more',  value: 0,          color: 'orange' },
    { icon: 'circle-tick',  value: 1,          color: 'green'  }
  ]
};
