import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { SessionStorageService } from '@shared/services';

@Component({
  selector:      'search-filter',
  templateUrl: './search-filter.component.html',
  host: { class: 'relative width-full'}
})
export class SearchFilterComponent implements OnDestroy {
  search: string;
  searchTimer: ReturnType<typeof setTimeout>;
  @Output() updateData = new EventEmitter<void>;
  constructor(
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnDestroy(): void {
    this.sessionStorageService.setSearchFlow(null);
  }

  changeSearchValue(event: Event): void {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => this.updateWithSearch((event.target as HTMLInputElement).value), 1000);
  }

  private updateWithSearch(query: string): void {
    this.sessionStorageService.setSearchFlow(query.trim());
    this.updateData.emit();
  }

  clearSearch(): void {
    this.search = '';
    this.updateWithSearch(this.search);
  }

}
