import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { from, map, of, switchMap } from 'rxjs';

import { PagingModel } from '@shared/models';
import { StandaloneMileageReportOverview} from '@shared/factories';
import { DBService, MileageReportsService, NotificationService, SessionStorageService } from '@shared/services';

@Component({
  selector:      'mileage-overview',
  templateUrl: './mileage-overview.component.html',
  host: { class: 'height-full du-flex-column gap-xs overflow-auto' }
})
export class MileageOverviewComponent implements OnInit {
  @Output() loadEntries = new EventEmitter<Function>;

  mileageReports: StandaloneMileageReportOverview[] = [];
  paging:         PagingModel;
  page:           number;
  constructor(
    private dbService:                DBService,
    private mileageReportsService:    MileageReportsService,
    private notificationService:      NotificationService,
    private sessionStorageService:    SessionStorageService
  ) {}

  ngOnInit(): void {
    this.loadEntries.emit(this.loadMileage.bind(this));

    this.sessionStorageService.paging.subscribe(paging => this.paging = paging);
    this.notificationService.wait();
    this.loadMileage();
  }

  loadMileage(reload: boolean = null): void {
    if (!this.paging || !this.paging.total_pages || !this.page || this.page < this.paging.total_pages || reload) {
      this.page = reload || !this.page ? 1 : this.page+1;
      this.notificationService.wait();
      this.mileageReportsService.loadMileageReports(this.page).pipe(
        switchMap(mileageReports => {
          if (this.page === 1) return from(this.dbService.loadMultipleFromDB('pendingUpload', { useCase: 'mm' })).pipe(
            map(pendingMm => pendingMm.map(mm => new StandaloneMileageReportOverview(mm))),
            map(pendingMm => [...pendingMm, ...mileageReports])
          );
          else return of(mileageReports);
        })
      ).subscribe(
        mileageReports => {
          if (reload) this.mileageReports = [...mileageReports];
          else        this.mileageReports = [...this.mileageReports, ...mileageReports];
          this.notificationService.close();
        },
        err => this.notificationService.alert(err),
        ()  => this.sessionStorageService.setPulltoRefreshState(true)
      );
    }
  }

  openMileageReport(mileage: StandaloneMileageReportOverview): void {
    this.sessionStorageService.pushDynamicComponent({
      component: 'MileageReportDetails',
      props: { mileage }
    });
  }

}
