import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';

import { StandaloneMileageReportOverview } from '@shared/factories';
import { SessionStorageService           } from '@shared/services';
import { collapse, slideUp               } from '@shared/animations';

@Component({
  selector:      'mileage-details',
  templateUrl: './mileage-details.component.html',
  host: { class: 'height-max-700-auto absolute bottom-0 left-0 right-0 du-flex-column btl-radius btr-radius absolute bg-white'},
  animations: [ slideUp, collapse ]
})
export class MileageReportDetailsComponent implements OnInit, OnDestroy {
  private readonly COMPONENT_NAME: string = 'MileageReportDetails';
  @Input() mileage:  StandaloneMileageReportOverview;
  @Input() extended: boolean;

  @HostBinding('@slideUp') get slideIn() { return 'open'; }
  constructor( private sessionStorageService: SessionStorageService ) {}

  ngOnInit(): void {
    this.sessionStorageService.pushOverflowStack(this.COMPONENT_NAME);
  }

  ngOnDestroy(): void {
    this.sessionStorageService.popOverflowStack(this.COMPONENT_NAME);
  }

  close(): void {
    this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME);
  }

}
