<li *ngFor="let entry of entries; let i = index" class="du-flex-column du-flex-center du-flex-1 height-50 b-radius gap-xs font-s pl-xxs pr-xxs pt-xs pb-xs hover-active" 
    (click)="daySelectCallback.emit(i)"
    [ngClass]="dayIsDisabled(entry)      ? 'b-grey color-disabled event-none' :
               entry.techErrorsAll.size  ? 'bg-red    color-white'            :
               entry.legalErrorsAll.size ? 'bg-orange color-white'            :
              !entry.placeholder         ? 'bg-blue   color-white'            :
               activeEntryIndex === i    ? 'bg-blue   color-white'            : 'bg-card-grey'">
  <span>{{ getEntryDate(entry) | date: 'EEE' }}</span>
  <span *ngIf="useCase !== 't'" class="bold">{{ getEntryDate(entry) | date: 'dd.MM' }}</span>
</li>
