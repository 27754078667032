import { Component, EventEmitter, Input, Output     } from '@angular/core';
import { DailyLocal, DailyLocalExtended, PauseLocal } from '@shared/factories';
import { collapse, fadeIn                           } from '@shared/animations';

interface CallbackModel {
  index: number;
  daily: DailyLocal | DailyLocalExtended;
}

@Component({
  selector:      'constructor-daily',
  templateUrl: './constructor-daily.component.html',
  host: { class: 'du-flex-column b-radius shadow relative' },
  animations: [ fadeIn, collapse ]
})
export class DailyConstructorItemComponent {
  @Input() index:           number;
  @Input() daily:           DailyLocal;
  @Input() useCase:         string;
  @Input() tutorialStepVal: number;

  @Output() validate       = new EventEmitter<CallbackModel>;
  @Output() adjustDaily    = new EventEmitter<CallbackModel>;
  @Output() resetToDefault = new EventEmitter<CallbackModel>;

  resetPlaceholder(): void {
    this.resetToDefault.emit({ index: this.index, daily: this.daily });
  }

  addDefaultDay() {
    this.daily.placeholder = false;
    this.adjustDaily.emit({ index: this.index, daily: this.daily });
  }

  updateTime(e: string, el: any) {
    let time = e.split(':');
    el.setHours(time[0], time[1], 0 , 0);
    this.validate.emit();
  }

  addPause(): void {
    let dailyStart = this.daily.pauses.length ? 
                     this.daily.pauses[this.daily.pauses.length - 1].end :
                     new Date(this.daily.startsAt.getTime() + (this.daily.endsAt.getTime() - this.daily.startsAt.getTime()) / 2);
    dailyStart.setSeconds(0);
    dailyStart.setMilliseconds(0);
    this.daily.pauses.push(new PauseLocal({
      id:    this.daily.latestPauseId,
      start: new Date(+dailyStart),
      end:   new Date(+dailyStart + 30*60*1000),
    }));
    this.validate.emit();
  }

  deletePause(pause: PauseLocal): void {
    let index = this.daily.pauses.findIndex(p => p.id === pause.id);
    this.daily.pauses.splice(index, 1);
    this.validate.emit();
  }

}
