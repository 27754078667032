import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';

import { FilterFlowModel       } from '@shared/models';
import { SessionStorageService } from '@shared/services';

import * as tabsConfig from './tabs.config';

@Component({
  selector:      'tabs-box',
  templateUrl: './tabs-box.component.html',
  host: { class: 'du-flex-justify bg-white gap-xs pl-m pr-m pt-s pb-s z-1 shadow-header', '[class.hide]': '!this.tabs' }
})
export class TabsBoxComponent implements OnChanges, OnDestroy {
  @Input()  useCase: string;
  @Output() updateData = new EventEmitter<FilterFlowModel>;

  tabs:  any[];
  tab:   string;
  field: string;
  constructor( private sessionStorageService: SessionStorageService ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['useCase'].firstChange || JSON.stringify(changes['useCase'].currentValue) !== JSON.stringify(changes['useCase'].previousValue)) {
      this.prepareDataByUseCase(changes['useCase'].currentValue);
    }
  }

  private prepareDataByUseCase(useCase: string): void {
    let tempFilter = (tabsConfig as any)[useCase];
    this.prepareData(tempFilter);
  }

  private prepareData({ tabs, field }: any): void {
    this.tabs = tabs;
    this.field = field;
  }

  ngOnDestroy(): void {
    this.sessionStorageService.setTabFlow(null);
  }

  changeTab(tab: string): void {
    if (this.tab === tab) this.tab = null;
    else this.tab = tab;

    let wrapper = document.getElementById('pull-to-refresh-container');
    wrapper.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    this.sessionStorageService.setTabFlow({
      field: this.field,
      value: this.tab
    });
    this.updateData.emit();
  }

}
