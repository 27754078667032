import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router       } from '@angular/router';
import { Location                     } from '@angular/common';
import { Observable, from, map, of, switchMap, tap } from 'rxjs';

import { collapse } from '@shared/animations';
import { AssignmentModel, DailyLocalExtendedModel } from '@shared/models';
import { ActivityReportLocal, Assignment, DailyLocalExtended, HolidayExtended, MileageReportLocal, VacationRequestLocal } from '@shared/factories';
import { SessionStorageService, DBService, NotificationService, UserService } from '@shared/services';

import { SUBMITION_TIME_RANGE } from '@globals';

interface AssignmentSelectItem extends Assignment {
  localDailies?: number;
  holidays?:     HolidayExtended[];
}

@Component({
  templateUrl: './assignment-select.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify p-xl' },
  animations: [ collapse ]
})
export class AssignmentSelectComponent implements OnInit, OnDestroy {
  useCase:           string;
  search:            string;

  assignments:       AssignmentSelectItem[] = [];
  tempAssignment:    AssignmentSelectItem;

  animate:           boolean;
  closeMileageTip:   boolean;
  closeDailiesTip:   boolean;
  closeHolidaysTips: boolean;
  constructor(
    private location:              Location,
    private router:                Router,
    private route:                 ActivatedRoute,
    private dbService:             DBService,
    private sessionStorageService: SessionStorageService,
    private notificationService:   NotificationService,
    public  userService:           UserService,
  ) {}

  ngOnInit(): void {
    this.useCase = this.route.snapshot.data['useCase'];
    this.setHeader();

    this.notificationService.wait();
    this.loadAssignments().pipe(
      switchMap(() => this.loadLocalDailies())
    ).subscribe(() => {
      this.notificationService.close();
      setTimeout(() => this.animate = true, 200);
    });
  }

  ngOnDestroy(): void {
    this.animate = false;
  }

  private loadAssignments(): Observable<Assignment[]> {
    return this.requestAssignmentsByUseCase().pipe(
      tap(assignments => {
        this.assignments = assignments;

        let temp = this.sessionStorageService.temporaryVacationRequest;
        if (temp?.assignment) {
          let active = this.assignments.find(a => a.id === temp.assignment.id);
          if (active) this.tempAssignment = active;
        }

        if ((temp as any)?.holidays) this.parceHolidays((temp as any)?.holidays);
      })
    );
  }

  private requestAssignmentsByUseCase(): Observable<Assignment[]> {
    if (this.useCase === 'vr') return this.requestAssignmentsForVacationRequests();
    if (this.useCase === 'mm') return this.requestAssignmentsForMileageReports();
    else                       return this.requestAssignments();
  }

  private requestAssignmentsForVacationRequests(): Observable<Assignment[]> {
    let assignments = this.sessionStorageService.temporaryAssignments;
    if (assignments) return of(assignments);
    else {
      this.router.navigateByUrl('time-tracking/home');
      return of(null);
    }
  }

  private requestAssignmentsForMileageReports(): Observable<Assignment[]> {
    return this.requestAssignments().pipe(
      map(assignments => assignments.filter(a => a.mileageData?.length)),
      map(assignments => assignments.filter(a => a.mileageData.find(md =>
        md.startsAt.getTime() <= new Date().getTime() && 
        md.endsAt.getTime()   >= new Date(new Date().getTime() - SUBMITION_TIME_RANGE).getTime()
      )))
    );
  }

  private requestAssignments(): Observable<Assignment[]> {
    return from(this.dbService.loadMultipleFromDB('assignments')).pipe(
      map((res: AssignmentModel[]) => res.map(a => new Assignment(a)).filter(a => 
        a.startsAt.getTime() <= new Date().getTime() && 
        a.endsAt.getTime()   >= new Date(new Date().getTime() - SUBMITION_TIME_RANGE).getTime()
      ).sort((a, b) => a.startsAt.getTime() - b.startsAt.getTime()))
    );
  }

  private loadLocalDailies(): Observable<DailyLocalExtended[]> {
    if (this.assignments && this.useCase === 'ar') return this.requestLocalDailies().pipe(tap(dailies => {
      if (dailies.length) this.parceLocalDaiies(dailies as DailyLocalExtended[]);
    }));
    return of(null);
  }

  private requestLocalDailies(): Observable<DailyLocalExtended[]> {
    return from(this.dbService.loadMultipleFromDB('localDailies')).pipe(
      map((res: DailyLocalExtendedModel[]) => res.map(d => new DailyLocalExtended(d)))
    );
  }

  private parceLocalDaiies(localDailies: DailyLocalExtended[]): void {
    localDailies.forEach(d => {
      let i = this.assignments.findIndex(a => a.id === d.assignment.id);
      if (!this.assignments[i].localDailies) this.assignments[i].localDailies = 0;
      ++this.assignments[i].localDailies;
    });
  }

  private parceHolidays(holidays: HolidayExtended[]): void {
    if (this.assignments?.length && holidays?.length) this.assignments.forEach(a => {
      a.holidays = holidays.filter(h => !h.states);
      if (a.stateISO !== 'null') a.holidays = [...a.holidays, ...holidays.filter(h => h.states?.includes(a.stateISO))];
    });    
  }

  selectAssignment(assignment: AssignmentSelectItem): void {
    this.tempAssignment    = assignment;
    this.animate           = true;
    this.closeMileageTip   = false;
    this.closeDailiesTip   = false;
    this.closeHolidaysTips = false;
  }

  confirmEBS(): void {
    let report = this.sessionStorageService.temporaryActivityReport;
    report = new ActivityReportLocal(Object.assign(report.toJSON(), { assignment: this.tempAssignment.toJSON() }));
    this.sessionStorageService.setTemporaryValue(report);
    this.router.navigate(['/time-tracking/ebs-details', this.tempAssignment.id, 'max']);
  }

  confirmAssignment(): void {
    if (this.useCase === 'vr') this.confirmAssignmentForVacationRequest();
    if (this.useCase === 'mm') this.confirmAssignmentForMileageReport();
    else this.confirmAssignmentForActivityReport();
    this.goNext();
  }

  private confirmAssignmentForVacationRequest(): void {
    let vacation = this.sessionStorageService.temporaryVacationRequest;
    if (!vacation.assignment || vacation.assignment.id !== this.tempAssignment.id) {
      vacation = new VacationRequestLocal(Object.assign(vacation.toJSON(), { assignment: this.tempAssignment.toJSON() }));
    }
    this.sessionStorageService.setTemporaryValue(vacation);
  }

  private confirmAssignmentForMileageReport(): void {
    let mileage = this.sessionStorageService.temporaryMileageReport;
    if (!mileage || !mileage.assignment || mileage.assignment.id !== this.tempAssignment.id) {
      let data = mileage ? mileage.toJSON() : {};
      mileage = new MileageReportLocal(Object.assign(data, { assignment: this.tempAssignment.toJSON() }));
    }
    this.sessionStorageService.setTemporaryValue(mileage);
  }

  private confirmAssignmentForActivityReport(): void {
    let report = this.sessionStorageService.temporaryActivityReport;
    if (report) {
      if     (!report.assignment) report = new ActivityReportLocal(Object.assign(report.toJSON(), { assignment: this.tempAssignment.toJSON() }));
      else if (report.assignment.id !== this.tempAssignment.id) {
        if (this.useCase === 'ar-t') report = new ActivityReportLocal(Object.assign(report.toJSON(), { assignment: this.tempAssignment.toJSON() }));
        else report = new ActivityReportLocal({ assignment: this.tempAssignment.toJSON() });
      }
    } else report = new ActivityReportLocal({ assignment: this.tempAssignment.toJSON() });
    this.sessionStorageService.setTemporaryValue(report);
  }

  private setHeader(): void {
    this.sessionStorageService.setHeaderControls({ left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.location.back() }] });
    if (this.useCase === 'ar' || this.useCase === 'ar-t') {
      this.sessionStorageService.setHeaderTitle('activityReport');
      this.sessionStorageService.setProgressBar(1, 6);
    } else if (this.useCase === 'pr' ) {
      this.sessionStorageService.setHeaderTitle('photoReport');
      this.sessionStorageService.setProgressBar(1, 4);
    } else if (this.useCase === 'vr' ) {
      this.sessionStorageService.setHeaderTitle('vacationRequest');
      this.sessionStorageService.setProgressBar(3, 5);
    } else if (this.useCase === 'mm' ) {
      this.sessionStorageService.setHeaderTitle('mileageMoney');
      this.sessionStorageService.setProgressBar(1, 5);
    }
    if (this.useCase !== 'ar-t' && !this.userService.checkTutorial(this.useCase)) this.sessionStorageService.pushDynamicComponent({
      component: 'TutorialScreen',
      props: { useCase: this.useCase }
    });
  }

  private goNext(): void {
    if (this.useCase === 'vr') this.router.navigateByUrl(`time-tracking/status-select-${this.useCase}`);
    else this.router.navigateByUrl(`time-tracking/week-select-${this.useCase}`);
  }

  assignmentReady(): boolean {
    return this.tempAssignment && this.tempAssignment.confirmed && (!this.tempAssignment.mileageData || this.tempAssignment.mileageData && this.userService.currentUserValue.mileageConfig);
  }

  mileageNotConfigured(): boolean {
    return this.tempAssignment && this.tempAssignment.confirmed && this.tempAssignment.mileageData && !this.userService.currentUserValue.mileageConfig;
  }

  openMileageConfig(): void {
    this.sessionStorageService.pushDynamicComponent({
      component: 'MileageConfigBox'
    });
  }

}
