import { Component, HostBinding, OnInit } from '@angular/core';
import { Location               } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { SessionStorageService  } from '@shared/services';
import { PagingModel            } from '@shared/models';

@Component({
  selector:      'entries-overview',
  templateUrl: './entries-overview.component.html',
  host: { class: 'height-full du-flex-column overflow' }
})
export class EntriesOverviewComponent implements OnInit {
  useCase:     string;
  paging:      PagingModel;
  loadEntries: Function;

  // @HostBinding('class') get class(): string {
  //   return `height-full du-flex-column overflow ${this.useCase !== 'n' && this.useCase !== 'r' ? 'p-xl' : 'pt-xl pb-xl'}`;
  // }

  constructor(
    private location:              Location,
    private router:                Router,
    private route:                 ActivatedRoute,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.useCase = this.route.snapshot.data['useCase'];
    this.prepareHeader();
    this.sessionStorageService.paging.subscribe(paging => this.paging = paging);
  }

  private prepareHeader(): void {
    this.prepareTitle();
    this.prepareControls();
    this.sessionStorageService.setProgressBar(null);
  }

  private prepareTitle(): void {
    if (this.useCase === 'ar') this.sessionStorageService.setHeaderTitle('activityReport');
    if (this.useCase === 'vr') this.sessionStorageService.setHeaderTitle('overviewVacation');
    if (this.useCase === 'pd') this.sessionStorageService.setHeaderTitle('sentDocument');
    if (this.useCase === 'sr') this.sessionStorageService.setHeaderTitle('salaryReports');
    if (this.useCase === 'mm') this.sessionStorageService.setHeaderTitle('mileageOverview');
    if (this.useCase === 'n' ) this.sessionStorageService.setHeaderTitle('notifications');
    if (this.useCase === 'r' ) this.sessionStorageService.setHeaderTitle('releases');
  }

  private prepareControls(): void {
    if (this.useCase === 'n' ||
        this.useCase === 'r') this.sessionStorageService.setHeaderControls({ left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.router.navigateByUrl('time-tracking/home') }] });
    else                      this.sessionStorageService.setHeaderControls({ left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.location.back()                            }] });
  }

  loadHandler(callback: Function): void {
    this.loadEntries = callback;
  }

}
