<div class="du-flex-align" [ngClass]="'bl-' + getStateColor() + '-5'">
  <div class="du-flex-column du-flex-1 gap-m p-s">
    <div class="du-flex-justify du-flex-align">
      <span class="du-flex-align font-600 gap-xxs pt-xs pb-xs">
        <span translate="cw"></span>
        <span>{{  vacation.startsOn | date: 'ww' }}</span>
        <span>({{ vacation.startsOn | date: 'dd' }}.{{ vacation.startsOn | date: 'MM' }} - {{ vacation.endsOn| date: 'dd' }}.{{ vacation.endsOn| date: 'MM' }})</span>
      </span>
  
      <span *ngIf="getLocalVacation().notSynced" class="du-flex-align gap-xs pt-xs pb-xs pl-s pr-s b-radius bg-light-orange color-orange">
        <span class="du-flex-center du-icon-offline font-l"></span>
        <span class="font-s" translate="notSynced"></span>
      </span>
      <span *ngIf="getOverviewVacation().createdByTempton" class="pt-xs pb-xs pl-s pr-s b-radius font-600 bg-light-blue color-blue">T</span>
    </div>

    <div class="du-flex-justify gap-m">
      <div class="du-flex-column gap-xs">
        <span class="font-s" translate="assignment"></span>
        <span class="font-500">{{ vacation.assignment && vacation.assignment.title || 'Kein Einsatz vorliegend' }}</span>
      </div>
      <div class="du-flex-column gap-m">
        <div class="du-flex-column gap-xs">
          <span class="font-s" translate="vacationType"></span>
          <span class="font-500 text-right">{{ vacation.leaveType | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
