import { Component, OnInit      } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location               } from '@angular/common';
import { Observable, forkJoin, from, map, of, switchMap, take } from 'rxjs';

import { ActivityReportLocal, Assignment, DailyLocal, DailyLocalExtended, MileageDataTimeFrame, MileageReportLocal } from '@shared/factories';
import { DBService, NotificationService, SessionStorageService, TimeService, WeeksService      } from '@shared/services';
import { DailyLocalExtendedModel, DailyLocalModel } from '@shared/models';
import { collapse } from '@shared/animations';

import { SUBMITION_WEEKS_LIMIT } from '@globals';

interface WeekModel {
  weekStart:     Date;
  weekEnd:       Date;
  mileage?:      WeekMileageModel;
  localDailies?: number;
}

interface WeekMileageModel {
  start: Date;
  end:   Date;
}

@Component({
  templateUrl: './week-select.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify p-xl' },
  animations: [ collapse ]
})
export class WeekSelectComponent implements OnInit {
  weeks:           WeekModel[] = this.weeksService.getWeeksRangeReverse(SUBMITION_WEEKS_LIMIT);
  activeWeek:      WeekModel;

  useCase:         string;
  animate:         boolean;
  closeMileageTip: boolean;
  constructor(
    private route:                 ActivatedRoute,
    private router:                Router,
    private location:              Location,
    private dbService:             DBService,
    private timeService:           TimeService,
    private weeksService:          WeeksService,
    private notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.useCase = this.route.snapshot.data['useCase'];
    this.setHeader();
    this.prepareForm();
  }

  private prepareForm(): void {
    if (this.useCase === 'mm') this.prepareMileageReport();
    else this.prepareActivityReport();
  }

  private prepareMileageReport(): void {
    let report = this.sessionStorageService.temporaryMileageReport;
    if (report) this.mapWeeks(report);
    else this.goToStart();
  }

  private prepareActivityReport(): void {
    this.notificationService.wait();
    this.sessionStorageService.temporaryActivityReportObservable.pipe(
      take(1),
      switchMap(report => {
        if (!report || !report.assignment) return of(null);
        let calls: Observable<any>[] = [of(report)];
        if (this.useCase === 'ar') calls.push(this.loadLocalDailies(report));
        return forkJoin(calls);
      })
    ).subscribe(
      (res: Array<ActivityReportLocal | DailyLocalExtended[]>) => {
        if (res) {
          this.mapWeeks(res[0] as ActivityReportLocal);
          if (res[1]) this.parceLocalDaiies(res[1] as DailyLocalExtended[]);
        } else this.goToStart();
        this.notificationService.close();
      },
      err => this.notificationService.alert(err)
    );
  }

  private loadLocalDailies(report: ActivityReportLocal): Observable<DailyLocalExtended[]>  {
    return from(this.dbService.loadMultipleFromDB('localDailies', { 'assignment.id': report.assignment.id })).pipe(
      map((res: DailyLocalExtendedModel[]) => res.map(d => new DailyLocalExtended(d)))
    );
  }

  private parceLocalDaiies(localDailies: DailyLocalExtended[]): void {
    localDailies.forEach(d => {
      let i = this.weeks.findIndex(w => d.startsAt.getTime() >= w.weekStart.getTime() && d.startsAt.getTime() <= w.weekEnd.getTime());
      if (i !== -1) {
        if (!this.weeks[i].localDailies) this.weeks[i].localDailies = 0;
        ++this.weeks[i].localDailies;
      }
    });
  }

  private mapWeeks(report: ActivityReportLocal | MileageReportLocal): void {
    this.weeks = this.weeks
    .map(w => {
      w.weekEnd.setHours(23, 59, 59);
      return w;
    })
    .filter(w => 
      w.weekStart.getTime() >= report.assignment.startsAt.getTime()  && 
      w.weekStart.getTime() <= report.assignment.endsAt.getTime()    ||

      w.weekEnd.getTime()   >= report.assignment.startsAt.getTime()  && 
      w.weekEnd.getTime()   <= report.assignment.endsAt.getTime()    ||

      w.weekStart.getTime() <= report.assignment.startsAt.getTime()  && 
      w.weekEnd.getTime()   >= report.assignment.endsAt.getTime()
    )
    .map((w, index, array) => {
      if (!index) w.weekStart.setDate(Math.max(w.weekStart.getDate(), report.assignment.startsAt.getDate()));
      if (index === array.length-1) w.weekEnd.setDate(Math.min(w.weekEnd.getDate(), report.assignment.endsAt.getDate()));
      
      w.mileage = this.getMileageRange(report.assignment, w);
      return w;
    });

    if (report.startDate) this.activeWeek = this.weeks.find(w => w.weekStart.getTime() === report.startDate.getTime());
  }

  selectWeek(week: WeekModel): void {
    if (!this.activeWeek || this.activeWeek.weekStart !== week.weekStart) {
      this.activeWeek      = week; 
      this.animate         = true; 
      this.closeMileageTip = false;
    }
  }

  confirmWeek(): void {
    if (this.useCase === 'mm') this.confirmWeekAsMileageMoney();
    else this.confirmWeekAsActivityReport();
  }

  private confirmWeekAsActivityReport(): void {
    let report: ActivityReportLocal = this.sessionStorageService.temporaryActivityReport;
    if (!report.startDate || report.startDate.getTime() !== this.activeWeek.weekStart.getTime()) {
      report = new ActivityReportLocal({
        dailyReports:            this.useCase === 'ar-t' ? this.adjustTemplate(report.dailyReports) : null,
        start_date:              this.activeWeek.weekStart.toISOString(),
        end_date:                this.activeWeek.weekEnd.toISOString(),
        mileageStart:            this.activeWeek.mileage?.start.toISOString(),
        mileageEnd:              this.activeWeek.mileage?.end.toISOString(),
        assignment:              report.assignment.toJSON(),
        attachment:              report.attachment,
        external_employee_notes: report.externalNote
      });
      this.sessionStorageService.setTemporaryValue(report);
    }

    if (this.activeWeek.localDailies) this.router.navigateByUrl(`time-tracking/report-use-dailies-ar`);
    else if (this.useCase === 'ar'  ) this.router.navigateByUrl('time-tracking/constructor-ar');
    else if (this.useCase === 'ar-t') this.router.navigateByUrl('time-tracking/constructor-ar-t');
    else if (this.useCase === 'pr'  ) this.router.navigateByUrl('time-tracking/photo-report-pr');
  }

  private confirmWeekAsMileageMoney(): void {
    let report: MileageReportLocal = this.sessionStorageService.temporaryMileageReport;
    if (!report.startDate || report.startDate.getTime() !== this.activeWeek.weekStart.getTime()) {
      report = new MileageReportLocal({
        start_date: this.activeWeek.weekStart.toISOString(),
        end_date:   this.activeWeek.weekEnd.toISOString(),

        work_days:  report.workDays?.length ? report.workDays.map(wd => wd.toJSON()) : [],
        assignment: report.assignment.toJSON()
      });
      this.sessionStorageService.setTemporaryValue(report);
    }

    this.router.navigateByUrl('time-tracking/constructor-mm');
  }

  private adjustTemplate(dailyReports: DailyLocal[]): DailyLocalModel[] {
    let start = this.weeksService.getStartOfWeek(this.activeWeek.weekStart);
    return dailyReports.map(day => {
      day.startsAt = this.timeService.setDateByWeekDay(day.startsAt, start, day.startsAt.getDay());
      day.endsAt   = this.timeService.setDateByWeekDay(day.endsAt,   start, day.endsAt.getDay());
      if (day.pauses && !day.pauses.length) day.pauses = [];
      day.pauses?.forEach(p => {
        p.start = this.timeService.setDateByWeekDay(p.start, start, day.startsAt.getDay());
        p.end   = this.timeService.setDateByWeekDay(p.end,   start, day.startsAt.getDay());
        if (p.end.getTime() < p.start.getTime()) p.end.setDate(p.end.getDate()+1);
      });
      return day.toJSON()
    });
  }

  private getMileageRange(assignment: Assignment, week: WeekModel): WeekMileageModel {
    let mileage = this.checkMileage(assignment, week);
    if (mileage) return {
      start: new Date(Math.max(mileage.startsAt.getTime(), week.weekStart.getTime())),
      end:   new Date(Math.min(mileage.endsAt.getTime(),   week.weekEnd.getTime()))
    };
    return null;
  }

  private checkMileage(assignment: Assignment, week: WeekModel): MileageDataTimeFrame {
    return assignment.mileageData?.find(md => 
      md.startsAt.getTime() <= week.weekStart.getTime() && md.endsAt.getTime() >= week.weekEnd.getTime() ||
      md.startsAt.getTime() >= week.weekStart.getTime() && md.endsAt.getTime() <= week.weekEnd.getTime() ||
      md.startsAt.getTime() >= week.weekStart.getTime() && md.endsAt.getTime() <= week.weekStart.getTime()
    );
  }

  private setHeader(): void {
    if (this.useCase === 'ar' || this.useCase === 'ar-t') {
      this.sessionStorageService.setHeaderTitle('activityReport');
      this.sessionStorageService.setProgressBar(2, 6);
    } else if (this.useCase === 'pr' ) {
      this.sessionStorageService.setHeaderTitle('photoReport');
      this.sessionStorageService.setProgressBar(2, 4);
    } else if (this.useCase === 'mm' ) {
      this.sessionStorageService.setHeaderTitle('mileageMoney');
      this.sessionStorageService.setProgressBar(2, 5);
    }
    this.sessionStorageService.setHeaderControls({
      left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.location.back() }]
    });
  }

  private goToStart(): void {
    if (this.useCase === 'ar-t') this.router.navigateByUrl('time-tracking/preselect-report-ar');
    if (this.useCase === 'ar'  ) this.router.navigateByUrl('time-tracking/preselect-report-ar');
    if (this.useCase === 'pr'  ) this.router.navigateByUrl('time-tracking/preselect-report-pr');
    if (this.useCase === 'mm'  ) this.router.navigateByUrl('time-tracking/assignment-select-mm');
  }

}
