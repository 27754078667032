import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';

import { SessionStorageService } from '@shared/services';
import { slideRight } from '@shared/animations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector:      'language-list',
  templateUrl: './language-list.component.html',
  host: { class: 'absolute-full-height left-0 width-300 du-flex-column btr-radius bbr-radius bg-white' },
  animations: [ slideRight ]
})
export class LanguageListComponent implements OnInit, OnDestroy {
  langList: any[] = [
    { value: 'en', label: 'English'    },
    { value: 'de', label: 'Deutsch'    },
    { value: 'hu', label: 'Magyar'     },
    { value: 'pl', label: 'Polski'     },
    { value: 'ro', label: 'Românesc'   },
    { value: 'ru', label: 'Русский'    },
    { value: 'tr', label: 'Türkçe'     },
    { value: 'cz', label: 'Český'      },
    { value: 'hr', label: 'Hrvatski'   },
    { value: 'ua', label: 'Українська' },
    { value: 'fr', label: 'Français'   },
    { value: 'es', label: 'Español'    },
    { value: 'it', label: 'Italiano'   },
  ];
  @HostBinding('@slideRight') get slideIn() { return 'open'; }
  private readonly COMPONENT_NAME: string = 'LanguageList';
  constructor(
    private sessionStorageService: SessionStorageService,
    public  translateService:      TranslateService,
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.pushOverflowStack(this.COMPONENT_NAME);
  }

  ngOnDestroy(): void {
    this.sessionStorageService.popOverflowStack(this.COMPONENT_NAME);
  }

  close(): void {
    this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME)
  }

  changeLang(lang: string): void {
    this.translateService.use(lang);
    this.close();
  }

}
