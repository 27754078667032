export interface HomeModel {
  label:     string;
  tutorial?: boolean;
  children:  HomeSectionModel[];
}

export interface HomeSectionModel {
  label:     string;
  useCase:   string;
  icon:      string;
  children?: HomeBlockModel[];

  collapse?: boolean;
}

export interface HomeBlockModel {
  id?:        number;
  title:      string;
  subtitle?:  string;

  useCase:    string;
  bgColor?:   string;

  leftIcon:   string;
  rightIcon?: string;
  rightLink?: string;
}

export const homeItems: HomeModel[] = [
  { label: 'timeTracking', tutorial: true, children: [
    { label: 'workTime',          useCase: 'ar',  icon: 'clock',    children: [
      { leftIcon: 'list-filled',  title: 'activityReportOverview',  useCase: 'ar-list',   rightIcon: 'arrow-big-right' },
      { leftIcon: 'today',        title: 'newDailyReport',          useCase: 'd-new',     rightIcon: 'circle-plus'     },
      { leftIcon: 'camera',       title: 'photoDailyReport',        useCase: 'pd-new',    rightIcon: 'circle-plus'     },
      { leftIcon: 'list',         title: 'dailyReportOverview',     useCase: 'd-list',    rightIcon: 'arrow-big-right' },
      { leftIcon: 'combine',      title: 'combineDailyReports',     useCase: 'd-combine', rightIcon: 'arrow-big-right' }
    ]},
    { label: 'vacationRequests',  useCase: 'vr',  icon: 'holiday',  children: [
      { leftIcon: 'calendar-sun', title: 'newVacationRequest',      useCase: 'vr-new',    rightIcon: 'circle-plus'     },
      { leftIcon: 'list',         title: 'overview',                useCase: 'vr-list',   rightIcon: 'arrow-big-right' }
    ]},
    { label: 'mileageAllowance',  useCase: 'mm',  icon: 'mileage',  children: [
      { leftIcon: 'mileage-alt',  title: 'mileageReport',           useCase: 'mm-new',    rightIcon: 'circle-plus'     },
      { leftIcon: 'list',         title: 'overview',                useCase: 'mm-list',   rightIcon: 'arrow-big-right' }
    ]}
  ]},
  { label: 'yourDocuments',                children: [
    { label: 'documents',         useCase: 'pd',  icon: 'document', children: [
      { leftIcon: 'camera',       title: 'photoDocument',           useCase: 'pd-new',    rightIcon: 'circle-plus'     },
      { leftIcon: 'list',         title: 'overview',                useCase: 'pd-list',   rightIcon: 'arrow-big-right' }
    ]},
    { label: 'ebs',               useCase: 'ebs', icon: 'case'  },
    { label: 'salary',            useCase: 'sr',  icon: 'money' }
  ]}
];
