import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { ActivityReportLocal } from '@shared/factories';
import { NotificationService, SessionStorageService } from '@shared/services';

@Component({
  selector:      'preselect-report-item',
  templateUrl: './preselect-report-item.component.html',
  host: { class: 'du-flex-column shadow' }
})
export class PreselectReportItemComponent {
  @Input()  report:  ActivityReportLocal;
  @Input()  useCase: string;
  @Output() submitReport = new EventEmitter<ActivityReportLocal>;
  @Output() deleteReport = new EventEmitter<number>;
  constructor(
    private router:                Router,
    private sessionStorageService: SessionStorageService,
    private notificationService:   NotificationService
  ) {}

  editReport(report: ActivityReportLocal): void {
    this.sessionStorageService.setTemporaryValue(report);
    this.router.navigateByUrl(`time-tracking/assignment-select-${this.useCase}`);
  }

  submitReportPrompt(report: ActivityReportLocal): void {
    this.notificationService.confirm({
      title: 'submit',
      desc: 'Are you sure you want to submit this report?',
      rightFn: () => this.submitReport.emit(report)
    });
  }

  deleteReportPrompt(report: ActivityReportLocal): void {
    this.notificationService.confirm({
      title: 'delete',
      desc: 'Are you sure you want to delete this report?',
      buttons: {
        left:  'yes',
        right: 'no',
      },
      leftFn: () => this.deleteReport.emit(report.id)
    });
  }

}
