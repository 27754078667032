import { Component, HostListener, OnInit } from '@angular/core';
import { defer, from, take } from 'rxjs';
import { Router            } from '@angular/router';

import { DBService, SessionStorageService, UserService } from '@shared/services';
import { collapse, collapseLeft } from '@shared/animations';
import { BannerModel            } from '@shared/models';
import { Banner                 } from '@shared/factories';

@Component({
  selector:      'home-banner',
  templateUrl: './home-banner.component.html',
  host: { class: 'bg-white shadow-header bbl-radius bbr-radius p-m fixed left-0 right-0 z-1' },
  animations: [collapse, collapseLeft]
})
export class HomeBannerComponent implements OnInit {
  banners:         Banner[];
  activeBanner:    number;
  collapsedBanner: boolean;
  private readonly COMPONENT_NAME: string = 'HomeBanner';

  @HostListener('document:click', ['$event']) close(event: MouseEvent) {
    if (this.collapsedBanner && !(event.target as HTMLElement).closest('home-banner')) {
      this.collapseBanner();
    }
  }
  constructor(
    private router:                Router,
    private dbService:             DBService,
    private userService:           UserService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.loadBanner();
  }

  private loadBanner(): void {
    defer(() => from(this.dbService.loadMultipleFromDB('banners', { userId: this.userService.currentUserValue.id }))).pipe(take(1)).subscribe(
      (banners: BannerModel[]) => { if (banners?.length) {
        let temp     = banners.filter(b => b.tag !== 'campaignBanner');
        let campaign = banners.find(b   => b.tag === 'campaignBanner');

        this.banners      = [campaign, ...temp].map(b => new Banner(b));
        this.activeBanner = 0;
      }}
    );
  }

  collapseBanner() {
    if (!this.collapsedBanner) this.sessionStorageService.pushOverflowStack(this.COMPONENT_NAME);
    else this.sessionStorageService.popOverflowStack(this.COMPONENT_NAME);
    this.collapsedBanner = !this.collapsedBanner;
  }

  redirect(banner: Banner): void {
    this.router.navigateByUrl(banner.url);
    this.collapseBanner();
  }

  bannerPrev(): void {
    if (this.activeBanner > 0) this.activeBanner = this.activeBanner-1;
  }

  bannerNext(): void {
    if (this.activeBanner+1 < this.banners.length) this.activeBanner = this.activeBanner+1;
  }

}