import { VacationRequestBasicModel, VacationRequestLocalModel, VacationRequestOverviewModel, VacationRequestSubmitModel, VacationRequestTempModel } from "@shared/models";
import { Assignment, Employee, Holiday } from "@shared/factories";

export class VacationRequestBasic {
  id:         number;
  userId:     number;

  leaveType:  string;
  startsOn:   Date;
  endsOn:     Date;

  reason:     string;
  assignment: Assignment;

  signer:     string;
  constructor(data: VacationRequestBasicModel) {
    this.id         = data.id         ? data.id                         : null;
    this.userId     = data.userId     ? data.userId                     : null;

    this.leaveType  = data.leave_type ? data.leave_type                 : null;
    this.startsOn   = this.parceDate(data.starts_on);
    this.endsOn     = this.parceDate(data.ends_on);

    this.reason     = data.reason     ? data.reason                     : null;
    this.assignment = data.assignment ? new Assignment(data.assignment) : null;

    this.signer     = data.signer     ? data.signer                     : null;
  }

  parceDate(date: any): Date {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  toJSON(): VacationRequestBasicModel {
    let obj: VacationRequestBasicModel = {
      userId:     this.userId     ? this.userId                 : null,
      leave_type: this.leaveType  ? this.leaveType              : null,
      starts_on:  this.startsOn   ? this.startsOn.toISOString() : null,
      ends_on:    this.endsOn     ? this.endsOn.toISOString()   : null,

      reason:     this.reason     ? this.reason                 : null,
      assignment: this.assignment ? this.assignment.toJSON()    : null,

      signer:     this.signer     ? this.signer                 : null,
    };
    if (this.id) obj = Object.assign(obj, { id: this.id });
    return obj;
  }
}

export class VacationRequestLocal extends VacationRequestBasic {
  signature: string;
  lifeState: string;
  useCase:   string;
  notSynced: boolean;
  constructor(data: VacationRequestLocalModel) {
    super(data);
    this.signature = data.signature ? data.signature : null;
    this.lifeState = data.lifeState ? data.lifeState : null;
    this.useCase   = data.useCase   ? data.useCase   : null;
    this.notSynced = data.notSynced ? data.notSynced : null;
  }

  get signedByCustomer():        boolean { return this.lifeState && this.lifeState === 'signed_by_customer';        }
  get waitingCustomerApproval(): boolean { return this.lifeState && this.lifeState === 'waiting_customer_approval'; }
  get waitingClarification():    boolean { return this.lifeState && this.lifeState === 'waiting_clarification';     }

  setSignedByCustomer():        void { this.lifeState = 'signed_by_customer';        }
  setWaitingCustomerApproval(): void { this.lifeState = 'waiting_customer_approval'; }
  setWaitingClarification():    void { this.lifeState = 'waiting_clarification';     }

  toJSON(): VacationRequestLocalModel {
    return Object.assign(super.toJSON(), {
      signature: this.signature ? this.signature : null,
      lifeState: this.lifeState ? this.lifeState : null,
      useCase:   this.useCase   ? this.useCase   : null,
      notSynced: this.notSynced ? this.notSynced : null
    });
  }

  toSubmitJSON(): VacationRequestSubmitModel {
    return {
      leave_type:                this.leaveType            ? this.leaveType              : null,
      starts_on:                 this.startsOn             ? this.startsOn.toISOString() : null,
      ends_on:                   this.endsOn               ? this.endsOn.toISOString()   : null,

      assignment_id:             this.assignment           ? this.assignment.id          : null,
      reason:                    this.reason               ? this.reason                 : null,
      external_comment:          this.reason               ? this.reason                 : null,

      signer:                    this.signer               ? this.signer                 : null,
      base64_customer_signature: this.signature            ? this.signature              : null,
      customer_rejection:        this.waitingClarification ? true                        : null
    };
  }

}

export class VacationRequestTemp extends VacationRequestLocal {
  assignments: Assignment[];
  constructor(data: VacationRequestTempModel) {
    super(data);
    this.assignments = data.assignments;
  }
}

export class VacationRequestOverview extends VacationRequestBasic {
  externalEmployee:        Employee;
  customerSignatureUrl:    string;
  state:                   string;
  creationInitiator:       string;
  holidays:                Holiday[];

  externalReview:          string;
  externalReviewedAt:      Date;
  externalComment:         string;
  externalReviewComment:   string;
  externalRejectionReason: string;
  reviewedByExternal:      Employee;

  customerReview:          string;
  customerReviewedAt:      Date;
  customerReviewComment:   string;
  customerRejectionReason: string;
  reviewedByCustomer:      Employee;

  internalReview:          string;
  internalReviewedAt:      Date;
  internalReviewComment:   string;
  internalRejectionReason: string;
  reviewedByInternal:      Employee;

  createdByTempton:        boolean;
  constructor(data: VacationRequestOverviewModel) {
    super(data);
    this.externalEmployee        = data.external_employee         ? new Employee(data.external_employee)    : null;
    this.customerSignatureUrl    = data.customer_signature_url    ? data.customer_signature_url             : null;
    this.state                   = data.state                     ? data.state                              : null;
    this.creationInitiator       = data.creation_initiator        ? data.creation_initiator                 : null;
    this.holidays                = data.holidays?.length          ? data.holidays.map(h => new Holiday(h))  : null;

    this.externalReviewedAt      = super.parceDate(data.external_reviewed_at);
    this.externalReview          = data.external_review           ? data.external_review                    : null;
    this.externalComment         = data.external_comment          ? data.external_comment                   : null;
    this.externalReviewComment   = data.external_review_comment   ? data.external_review_comment            : null;
    this.externalRejectionReason = data.external_rejection_reason ? data.external_rejection_reason          : null;
    this.reviewedByExternal      = data.reviewed_by_external      ? new Employee(data.reviewed_by_external) : null;

    this.customerReviewedAt      = super.parceDate(data.customer_reviewed_at);
    this.customerReview          = data.customer_review           ? data.customer_review                    : null;
    this.customerReviewComment   = data.customer_review_comment   ? data.customer_review_comment            : null;
    this.customerRejectionReason = data.customer_rejection_reason ? data.customer_rejection_reason          : null;
    this.reviewedByCustomer      = data.reviewed_by_customer      ? new Employee(data.reviewed_by_customer) : null;

    this.internalReviewedAt      = super.parceDate(data.internal_reviewed_at);
    this.internalReview          = data.internal_review           ? data.internal_review                    : null;
    this.internalReviewComment   = data.internal_review_comment   ? data.internal_review_comment            : null;
    this.internalRejectionReason = data.internal_rejection_reason ? data.internal_rejection_reason          : null;
    this.reviewedByInternal      = data.reviewed_by_internal      ? new Employee(data.reviewed_by_internal) : null;

    this.createdByTempton        = this.creationInitiator === 'internal';
  }

  toJSON(): VacationRequestOverviewModel {
    return Object.assign(super.toJSON(), {
      external_employee:         this.externalEmployee        ? this.externalEmployee.toJSON()        : null,
      customer_signature_url:    this.customerSignatureUrl    ? this.customerSignatureUrl             : null,
      state:                     this.state                   ? this.state                            : null,
      creation_initiator:        this.creationInitiator       ? this.creationInitiator                : null,
      holidays:                  this.holidays                ? this.holidays.map(h => h.toJSON())    : null,

      external_review:           this.externalReview          ? this.externalReview                   : null,
      external_reviewed_at:      this.externalReviewedAt      ? this.externalReviewedAt.toISOString() : null,
      external_comment:          this.externalComment         ? this.externalComment                  : null,
      external_review_comment:   this.externalReviewComment   ? this.externalReviewComment            : null,
      external_rejection_reason: this.externalRejectionReason ? this.externalRejectionReason          : null,
      reviewed_by_external:      this.reviewedByExternal      ? this.reviewedByExternal.toJSON()      : null,

      customer_review:           this.customerReview          ? this.customerReview                   : null,
      customer_reviewed_at:      this.customerReviewedAt      ? this.customerReviewedAt.toISOString() : null,
      customer_review_comment:   this.customerReviewComment   ? this.customerReviewComment            : null,
      customer_rejection_reason: this.customerRejectionReason ? this.customerRejectionReason          : null,
      reviewed_by_customer:      this.reviewedByCustomer      ? this.reviewedByCustomer.toJSON()      : null,

      internal_review:           this.internalReview          ? this.internalReview                   : null,
      internal_reviewed_at:      this.internalReviewedAt      ? this.internalReviewedAt.toISOString() : null,
      internal_review_comment:   this.internalReviewComment   ? this.internalReviewComment            : null,
      internal_rejection_reason: this.internalRejectionReason ? this.internalRejectionReason          : null,
      reviewed_by_internal:      this.reviewedByInternal      ? this.reviewedByInternal.toJSON()      : null,
    });
  }

}

