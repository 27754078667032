<div class="du-flex-column gap-s">
  <div class="du-flex-justify">
    <div class="du-flex-column gap5"><span class="bold" translate="from"></span><input class="input-date" type="date" [min]="minDate | date:'yyyy-MM-dd'" [max]="maxDate | date:'yyyy-MM-dd'" [ngModel]="startsOn | date:'yyyy-MM-dd'" (ngModelChange)="dateChangedStart($event)"></div>
    <div class="du-flex-column gap5"><span class="bold" translate="to"  ></span><input class="input-date" type="date" [min]="minDate | date:'yyyy-MM-dd'" [max]="maxDate | date:'yyyy-MM-dd'" [ngModel]="endsOn   | date:'yyyy-MM-dd'" (ngModelChange)="dateChangedEnd($event)"  ></div>
  </div>

  <span class="du-flex-align gap-xs" *ngIf="totalVacationDays()">
    <span translate="totalWorkDays"></span>
    <span class="du-flex-align gap-xxs">
      <span>{{ workingVacationDays() }}</span>
      <span *ngIf="workingVacationDays() === 1" translate="day"></span>
      <span *ngIf="workingVacationDays() > 1"   translate="days"></span>
    </span>
  </span>

  <span class="lh-l font-s" *ngIf="leaveType === 'special' && reason">
    <span *ngIf="reason === 'wedding_birth'"            translate="weddingBirthHint"></span>
    <span *ngIf="reason === 'death_of_close_relatives'" translate="deathOfCloseRelativesHint"></span>
    <span *ngIf="reason === 'death_of_relatives'"       translate="deathOfRelativesHint"></span>
    <span class="ml-xxs color-orange">({{ reason === 'death_of_close_relatives' ? '2' : '1' }} {{ reason === 'death_of_close_relatives' ? 'days' : 'day' | translate }})</span>
  </span>

  <ul class="du-flex-column gap-xs font-s color-orange" *ngIf="holidaysList && holidaysList.length">
    <span translate="vacationRequestIncludesPublicHolidays"></span>
    <li class="ml-xs du-flex-align du-flex-justify gap-m" *ngFor="let holiday of holidaysList">
      <span class="du-flex-align gap-xs">
        <span class="circle-xxs bg-orange"></span>
        <span class="du-flex-column gap-xxs">
          <span>{{ holiday.name }}</span>
          <span class="font-xs" *ngIf="holiday.states">{{ holiday.states }}</span>
        </span>
      </span>
      <span class="roboto font-400">{{ holiday.date | date: 'dd.MM.yyyy' }}</span>
    </li>
  </ul>
</div>

<div class="du-flex-column gap-xl mta">
  <!-- <div class="du-flex-column bg-light-orange b-radius p-xs" @collapse *ngIf="availableDays && validUntil">
    <div class="du-flex-align-start du-flex-justify gap-xs overflow">
      <img  class="width-20" src="assets/svg/warning.svg">
      <span *ngIf="availableDays" class="font-s lh-l du-flex-1" translate="vacationDaysAvailable" [translateParams]="{ vacationDaysBudget: availableDays, validUntil: validUntil | date:'dd.MM.yyyy' }"></span>
    </div>
  </div> -->

   <div class="du-flex-column bg-light-blue b-radius p-xs" @collapse *ngIf="!closeHint && assignments && startsOn && endsOn && checkForAssignment()">
    <div class="du-flex-align-start du-flex-justify gap-xs overflow">
      <span class="du-icon-warning color-blue font-icon"></span>
      <span class="font-s lh-l du-flex-1" translate="assignmentAvailable"></span>
      <span class="du-icon-close color-black font-l" (click)="closeHint = true"></span>
    </div>
  </div>

  <div class="du-flex" @collapse *ngIf="assignments && startsOn && endsOn && !checkForAssignment()">
    <div class="du-flex-1 overflow">
      <button class="width-full btn-primary btn-round" (click)="saveVacation()" translate="save"></button>
    </div>
  </div>

  <button class="width-full btn-primary btn-round" (click)="confirmVacation()" [disabled]="!startsOn || !endsOn">{{ (assignments && checkForAssignment() ? 'next' : 'submit') | translate }}</button>
</div>
