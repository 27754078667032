import { formatDate           } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { WeeksService         } from '@shared/services';

@Injectable({
  providedIn: 'root'
})
export class FormatService {
  constructor(private injector:  Injector) { }

  parseDate(date: any): Date {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  parseDateISO(date: Date): string {
    return date && date instanceof Date ? date.toISOString() : null;
  }

  parseNumberToString(dateNumber: number): string {
    return this.parseNumberToDateString(dateNumber).replace(':', '');
  }

  parseNumberToDateString(dateNumber: number): string {
    let fullMins = dateNumber / 1000 / 60;
    let hours = Math.floor(fullMins / 60) ;
    let mins  = fullMins - (hours * 60);
    return `${hours}: ${mins}`;
  }

  getStringDuration(string: string): number {
    let mins, hours, date = string.split('');
    if (date.length === 4) hours = `${date[0]}${date[1]}`;
    else hours = `${date[0]}`;
    mins = `${date[date.length-2]}${date[date.length-1]}`;
    return this.getTimeStringDuration(`${hours}:${mins}`);
  }

  getTimeStringDuration(timeString: string): number {
    let { hours, mins } = this.parseTimeString(timeString);
    return ((hours * 60) + mins) * 60 * 1000;
  }

  parseTimeString(timeString: string): { hours: number, mins: number } {
    let time  = timeString.split(':');
    let hours = +time[0];
    let mins  = +time[1];

    return { hours, mins };
  }

  parseTime(date: Date, timeString: string): Date {
    let { hours, mins } = this.parseTimeString(timeString);
    return date && new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, mins, 0);
  }

  toTimeString(date: Date): string {
    return `${date.getHours()}:${date.getMinutes()}`;
  }

  static parseToPayFormat(val: any, to?: any): string {
    if      (!val || !isNaN(val))     return this.parseNumberToComaString(+val, to);
    else if (typeof val === 'string') return this.parseNumberToComaString(parseFloat(val.split(",").join(".")), to);
    return val;
  }

  static parseNumberToComaString(val: any, to: number = 2): string {
    return val.toFixed(to).replace('.', ',');
  }

  calendarWeek(date: Date): string {
    const weeksService = this.injector.get(WeeksService);
    return `KW ${weeksService.getWeekNumber(date) > 52 ? '01': weeksService.getWeekNumber(date)}`;
  }

  period(start: Date, end: Date): string {
    return `${formatDate(start, 'dd.MM.yyyy','de')} - ${formatDate(end, 'dd.MM.yyyy', 'de')}`;
  }

  isValidDate(date: Date): Date {
    if (!date)                              return null;
    if (date.toString() === 'Invalid Date') return null;
    if (isNaN(date.getTime()))              return null;
    if (date.getFullYear() === 1970)        return null;
    return date;
  }

  notNull(value: unknown): boolean {
    return value !== undefined && value !== null;
  }

}
