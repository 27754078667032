import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { PushNotificationOverview } from '@shared/factories';
import { NotificationService, NotificationsOverviewService } from '@shared/services';

@Component({
  selector:      'notifications',
  templateUrl: './notifications.component.html',
  host: { class: 'height-full du-flex-column gap-xs overflow-auto' }
})
export class NotificationsComponent implements OnInit {
  @Output() loadEntries = new EventEmitter<Function>;

  page:          number                     = 0;
  notifications: PushNotificationOverview[] = [];
  constructor(
    private notificationService:          NotificationService,
    private notificationsOverviewService: NotificationsOverviewService,
  ) {}

  ngOnInit(): void {
    this.loadEntries.emit(this.loadNotifications);
    this.loadNotifications();
  }

  loadNotifications(): void {
    this.notificationService.wait();
    this.page = this.page ? this.page+1 : 1;
    this.notificationsOverviewService.syncNotifications(this.page).subscribe(
      notifications => {
        this.notifications = [...this.notifications, ...notifications];
        this.notificationService.close();
      },
      err => this.notificationService.alert(err)
    );
  }

}
