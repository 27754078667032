<div class="du-flex-align gap-s p-l">
  <span class="du-flex-center du-icon-arrow-big-left font-xl" (click)="close()"></span>
  <span class="font-16 color-grey bold" translate="sortBy"></span>
</div>

<div class="du-flex-column">
  <ul class="du-flex-column gap-xs pl-m pr-m pb-m">
    <li class="du-flex-align du-flex-justify p-s bg-grey b-radius" *ngFor="let sort of entries" (click)="sortBy(sort)">
      <span>{{ sort.label | translate }}</span>
      <span *ngIf="!sort.active" class="du-flex-center font-xl color-blue du-icon-circle"     (click)="close()"></span>
      <span *ngIf=" sort.active" class="du-flex-center font-xl color-blue du-icon-circle-dot" (click)="close()"></span>
    </li>
  </ul>
  <div class="du-flex-align p-m">
    <button class="btn-primary btn-round width-full" translate="showResults" (click)="applySort()"></button>
  </div>
</div>
