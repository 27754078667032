import { formatDate } from "@angular/common";

import {
  MileageReportBasicModel,
  MileageReportLocalModel,
  MileageReportPrefillModel,
  MileageReportSubmitModel,
  MileageWorkDayBasicModel,
  MileageWorkDayLocalModel,
  MileageWorkDayModel,
  StandaloneMileageReportModel
} from "@shared/models";
import { Assignment, AssignmentBasic, ErrorsHandlerMixin } from "@shared/factories";

export class MileageReportBasic {
  id:         number;
  userId:     number;

  startDate:  Date;
  endDate:    Date;

  workDays:   MileageWorkDay[];
  assignment: AssignmentBasic;
  constructor(data: MileageReportBasicModel) {
    this.id         = data.id                ? data.id                                          : null;
    this.userId     = data.userId            ? data.userId                                      : null;

    this.startDate  = this.parceDate(data.start_date);
    this.endDate    = this.parceDate(data.end_date);

    this.workDays   = data.work_days?.length ? data.work_days.map(wd => new MileageWorkDay(wd)) : null;
    this.assignment = data.assignment        ? new AssignmentBasic(data.assignment)             : null;
  }

  get totalKm(): number {
    return this.workDays?.reduce((sum, val) => sum += val.amountOfKm, 0) || 0;
  }

  parceDate(date: any): Date {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  toJSON(): MileageReportBasicModel {
    let obj: MileageReportBasicModel = {
      userId:     this.userId           ? this.userId                          : null,
      start_date: this.startDate        ? this.startDate.toISOString()         : null,
      end_date:   this.endDate          ? this.endDate.toISOString()           : null,

      work_days:  this.workDays?.length ? this.workDays.map(wd => wd.toJSON()) : null,
      assignment: this.assignment       ? this.assignment.toJSON()             : null,
    };
    if (this.id) obj = Object.assign(obj, { id: this.id });
    return obj;
  }
}

export class MileageReportPrefill {
  assignmentId: number;
  ebsDataId:    number;

  startDate:    Date;
  endDate:      Date;

  workDays:     MileageWorkDayBasic[];
  constructor(data: MileageReportPrefillModel) {
    this.assignmentId = data.assignment_id     ? data.assignment_id                                     : null;
    this.ebsDataId    = data.ebs_data_id       ? data.ebs_data_id                                       : null;

    this.startDate    = this.parceDate(data.start_date);
    this.endDate      = this.parceDate(data.end_date);

    this.workDays     = data.work_days?.length ? data.work_days.map(wd => new MileageWorkDayBasic(wd)) : null;
  }

  private parceDate(date: any): Date {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

}

export class MileageReportLocal extends MileageReportBasic {
  assignment:     Assignment;
  workDays:       MileageWorkDayLocal[];

  ebsDataId:      number;
  licensePlateId: number;

  useCase:        boolean;
  notSynced:      boolean;
  duplicate:      boolean;
  constructor(data: MileageReportLocalModel) {
    super(data);

    this.assignment     = data.assignment        ? new Assignment(data.assignment)                       : null;
    this.workDays       = data.work_days?.length ? data.work_days.map(wd => new MileageWorkDayLocal(wd)) : null;

    this.ebsDataId      = data.ebs_data_id       ? data.ebs_data_id                                      : null;
    this.licensePlateId = data.license_plate_id  ? data.license_plate_id                                 : null;

    this.useCase        = data.useCase           ? data.useCase                                          : null;
    this.notSynced      = data.notSynced         ? data.notSynced                                        : null;
    this.duplicate      = data.duplicate         ? data.duplicate                                        : null;
  }

  get activeWorkDays(): MileageWorkDayLocal[] { return this.workDays ? this.workDays.filter(wd => !wd.placeholder) : []; }
  get techErrors():  Set<string> { return new Set(this.activeWorkDays.reduce((set, d) => set = [...set, ...d.techErrorsAll],  [])); }

  toSubmitJSON(): MileageReportSubmitModel {
    return {
      start_date:       formatDate(this.startDate, 'yyyy-MM-dd', 'de'),
      end_date:         formatDate(this.endDate,   'yyyy-MM-dd', 'de'),
      assignment_id:    this.assignment.id,
      ebs_data_id:      this.ebsDataId,
      license_plate_id: this.licensePlateId,
      work_days:        this.workDays.map(wd => ({
        date:                      formatDate(wd.date, 'yyyy-MM-dd', 'de'),
        amount_of_km:              wd.amountOfKm+'',
        assignment_address:        wd.assignmentAddress.trim(),
        external_employee_address: wd.externalEmployeeAddress.trim()
      })),
      confirm_report_type_duplicate: this.duplicate
    };
  }

  toJSON(): MileageReportLocalModel {
    return Object.assign(super.toJSON(), {
      assignment:       this.assignment       ? this.assignment.toJSON()             : null,
      work_days:        this.workDays?.length ? this.workDays.map(wd => wd.toJSON()) : null,

      ebs_data_id:      this.ebsDataId        ? this.ebsDataId                       : null,
      license_plate_id: this.licensePlateId   ? this.licensePlateId                  : null,

      useCase:          this.useCase          ? this.useCase                         : null,
      notSynced:        this.notSynced        ? this.notSynced                       : null,
      duplicate:        this.duplicate        ? this.duplicate                       : null,
    });
  }

}

export class StandaloneMileageReportOverview extends MileageReportBasic {
  createdAt:        Date;
  archivedAt:       Date;

  internalNote:     string;
  internalReview:   string;
  state:            boolean;

  internalApproved: boolean;
  internalAwaiting: boolean;
  internalRejected: boolean;

  edited:           boolean;
  constructor(data: StandaloneMileageReportModel) {
    super(data);
    this.createdAt        = super.parceDate(data.created_at);
    this.archivedAt       = super.parceDate(data.archived_at);

    this.internalNote     = data.internal_note   ? data.internal_note   : null;
    this.internalReview   = data.internal_review ? data.internal_review : null;
    this.state            = data.state           ? data.state           : null;

    this.edited           = data.edited          ? data.edited          : null;

    this.internalApproved =  this.internalReview === 'approved';
    this.internalAwaiting = !this.internalReview;
    this.internalRejected =  this.internalReview === 'rejected';
  }

  toJSON(): StandaloneMileageReportModel {
    return Object.assign(super.toJSON(), {
      id:              this.id             ? this.id                       : null,
      created_at:      this.createdAt      ? this.createdAt.toISOString()  : null,
      archived_at:     this.archivedAt     ? this.archivedAt.toISOString() : null,

      start_date:      this.startDate      ? this.startDate.toISOString()  : null,
      end_date:        this.endDate        ? this.endDate.toISOString()    : null,

      internal_note:   this.internalNote   ? this.internalNote             : null,
      internal_review: this.internalReview ? this.internalReview           : null,
      state:           this.state          ? this.state                    : null,

      assignment:      this.assignment     ? this.assignment.toJSON()      : null,
      edited:          this.edited         ? this.edited                   : null
    });
  }

}

export class MileageWorkDayBasic {
  date:                    Date;
  amountOfKm:              number;

  externalEmployeeAddress: string;
  assignmentAddress:       string;
  constructor(data: MileageWorkDayBasicModel) {
    this.date                    = this.parceDate(data.date);
    this.amountOfKm              = data.amount_of_km              ? parseFloat(data.amount_of_km)  : null;

    this.externalEmployeeAddress = data.external_employee_address ? data.external_employee_address : null;
    this.assignmentAddress       = data.assignment_address        ? data.assignment_address        : null;
  }

  private parceDate(date: any): Date {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  toJSON(): MileageWorkDayBasicModel {
    return {
      date:                      this.date                    ? this.date.toISOString()      : null,
      amount_of_km:              this.amountOfKm              ? this.amountOfKm+''           : null,

      external_employee_address: this.externalEmployeeAddress ? this.externalEmployeeAddress : null,
      assignment_address:        this.assignmentAddress       ? this.assignmentAddress       : null,
    };
  }

}

export class MileageWorkDay extends MileageWorkDayBasic {
  id:             number;
  mileageMoneyId: number;
  constructor(data: MileageWorkDayModel) {
    super(data);
    this.id             = data.id               ? data.id               : null;
    this.mileageMoneyId = data.mileage_money_id ? data.mileage_money_id : null;
  }

  toJSON(): MileageWorkDayModel {
    return Object.assign(super.toJSON(), {
      id:               this.id             ? this.id             : null,
      mileage_money_id: this.mileageMoneyId ? this.mileageMoneyId : null,
    });
  }

}

export class MileageWorkDayLocal extends ErrorsHandlerMixin(MileageWorkDay) {
  placeholder: boolean;
  constructor(data: MileageWorkDayLocalModel) {
    super(data);
    this.placeholder = data.placeholder ? data.placeholder : null;
  }

  toJSON(): MileageWorkDayLocalModel {
    return Object.assign(super.toJSON(), {
      placeholder: this.placeholder ? this.placeholder : null
    });
  }

}
