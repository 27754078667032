import { HolidayExtendedModel, HolidayModel } from "@shared/models";

export class Holiday {
  date:        Date;
  name:        string;
  stateISO:   string;
  countryISO: string;
  constructor(data: HolidayModel | Holiday) {
    this.date       = new Date(this.parceDate(data.date).setHours(0,0,0,0));
    this.name       = data.name;
    this.stateISO   = (data as Holiday).stateISO   || (data as HolidayModel).state_iso   || 'null';
    this.countryISO = (data as Holiday).countryISO || (data as HolidayModel).country_iso || '';
  }

  private parceDate(date: any) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  toJSON(): HolidayModel {
    return {
      date:        this.date       ? this.date.toISOString() : null,
      name:        this.name       ? this.name               : null,
      state_iso:   this.stateISO   ? this.stateISO           : null,
      country_iso: this.countryISO ? this.countryISO         : null
    };
  }

}

export class HolidayExtended extends Holiday {
  states: string[];
  constructor(data: HolidayExtendedModel) {
    super(data);

    this.states = data.states?.length ? data.states : null;
  }
}
