<div class="du-flex-align" [ngClass]="'bl-' + getStateColor() + '-5'">
  <div class="du-flex-column du-flex-1 gap-m p-s">
    <div class="du-flex-justify du-flex-align">
      <span class="du-flex-align font-600 gap-xxs pt-xs pb-xs">
        <span translate="cw"></span>
        <span>{{  mileage.startDate | date: 'ww' }}</span>
        <span>({{ mileage.startDate | date: 'dd' }}.{{ mileage.startDate | date: 'MM' }} - {{ mileage.endDate | date: 'dd' }}.{{ mileage.endDate | date: 'MM' }})</span>
      </span>
  
      <!-- <span *ngIf="mileage.notSynced" class="du-flex-align gap-xs pt-xs pb-xs pl-s pr-s b-radius bg-light-orange color-orange">
        <span class="du-flex-center du-icon-offline font-l"></span>
        <span class="font-s" translate="notSynced"></span>
      </span> -->
    </div>

    <div class="du-flex-justify gap-m">
      <div class="du-flex-column gap-xs">
        <span class="font-s" translate="assignment"></span>
        <span class="font-500">{{ mileage.assignment.title }}</span>
      </div>
      <div class="du-flex-column gap-m">
        <div class="du-flex-column gap-xs">
          <span class="font-s" translate="distance"></span>
          <span class="font-500 nowrap text-right">{{ mileage.totalKm | distanceFormat }} km</span>
        </div>
      </div>
    </div>
  </div>
</div>
  