import { Component, OnInit      } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location               } from '@angular/common';
import { take                   } from 'rxjs';
import { TranslateService       } from '@ngx-translate/core';

import { ActivityReportLocal, MileageReportLocal } from '@shared/factories';
import { MileageReportsService, NotificationService, SessionStorageService } from '@shared/services';

@Component({
  templateUrl: './report-confirm.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify' }
})
export class ReportConfirmComponent implements OnInit {
  report:  ActivityReportLocal | MileageReportLocal;
  useCase: string;
  constructor(
    private route:                 ActivatedRoute,
    private router:                Router,
    private location:              Location,
    private mileageReportsService: MileageReportsService,
    private notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService,
    private translateService:      TranslateService,
  ) {}

  ngOnInit(): void {
    this.useCase = this.route.snapshot.data['useCase'];
    this.prepareHeader();
    this.prepareReport();
  }

  private prepareReport(): void {
    let report: ActivityReportLocal | MileageReportLocal;
    if (this.useCase === 'mm') report = this.sessionStorageService.temporaryMileageReport;
    else                       report = this.sessionStorageService.temporaryActivityReport;

    if (this.validForSubmit(report)) this.report = report;
    else this.goHome();
  }

  private goHome(): void {
    this.router.navigateByUrl('time-tracking/home');
  }

  private prepareHeader(): void {
    if (this.useCase === 'ar' || this.useCase === 'ar-t') {
      this.sessionStorageService.setHeaderTitle('activityReport');
      this.sessionStorageService.setProgressBar(4, 6);
    } else if (this.useCase === 'dr') {
      this.sessionStorageService.setHeaderTitle('combineDailyReports');
      this.sessionStorageService.setProgressBar(null);
    }  else if (this.useCase === 'mm') {
      this.sessionStorageService.setHeaderTitle('mileageMoney');
      this.sessionStorageService.setProgressBar(4, 5);
    }

    this.sessionStorageService.setHeaderControls({
      left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.location.back() }]
    });
  }

  callbackHandler(): void {
    if (this.useCase === 'ar') this.router.navigateByUrl(`time-tracking/status-select-${this.useCase}`);
    if (this.useCase === 'mm') this.submitMileage();
  }

  private submitMileage(duplicate: boolean = null): void {
    this.notificationService.wait();
    this.mileageReport.duplicate = duplicate;
    this.mileageReportsService.submitMileageReport(this.mileageReport).subscribe(
      mileage => this.notificationService.close(),
      err     => {
        if (err[0]?.code === 'duplicates_mileage_report_within_type' ||
            err[0]?.code === 'duplicates_both_mileage_report_types') this.confirmDuplicate(err[0].code);
        else this.notificationService.alert(err);
      }
    );
  }

  private confirmDuplicate(errorString: string): void {
    this.translateService.get(errorString).pipe(take(1)).subscribe((res: string) => {
      this.notificationService.confirm({
        title: 'confirm',
        desc: res,
        rightFn: () => this.submitMileage(true)
      });
    });
  }

  private validForSubmit( report:  ActivityReportLocal | MileageReportLocal): boolean {
    if (!report || !report.assignment || !report.startDate) return false;
    if (this.useCase !== 'mm') return !!(report as ActivityReportLocal).dailyReports?.length;
    if (this.useCase === 'mm') return !!(report as MileageReportLocal).workDays?.length;

    return true;
  }

  get activityReport(): ActivityReportLocal { return this.report as ActivityReportLocal; }
  get mileageReport():  MileageReportLocal  { return this.report as MileageReportLocal;  }

}
