<div class="du-flex-column gap-xs" *ngIf="banners && banners.length">
  <div class="du-flex gap-s">
    <div class="du-flex-column" *ngIf="!collapsedBanner" [@collapseLeft]="{ value: ':enter', params: { delay: '250' }}">
      <div class="du-flex-column overflow">
        <div class="bg-center-y bg-cover bg-grey bg-no-repeat b-radius height-48 width-48" [style.background-image]="'url('+banners[activeBanner].image+')'"></div>
      </div>
    </div>
    <span class="du-flex-1 du-flex-column animate-padding overflow" [class.pt-s]="!collapsedBanner">
      <span class="animate-font font-600" [ngClass]="collapsedBanner ? 'font-20' : 'font-l'">Kandidatensuche</span>
      <div class="du-flex-column" @collapse *ngIf="!collapsedBanner">
        <span class="text-s ellipsis">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in r</span>
      </div>
    </span>
    <span class="du-flex-center p-s bg-grey b-radius text-xl color-blue" (click)="collapseBanner()">
      <span class="du-flex-center du-icon-arrow-drop-down animate-rotate" [class.rotate]="collapsedBanner"></span>
    </span>
  </div>

  <div class="du-flex-column" [@collapse]="{ value: ':enter', params: { delay: '250' }}" *ngIf="collapsedBanner">
    <div class="du-flex-column gap-m overflow">
      <div class="du-flex-align-strech">
        <div *ngFor="let banner of banners" class="du-flex-column du-flex-justify gap-s du-flex-shrink-0 width-full animate-transform"
             [style.transform]="'translate(-' + 100*activeBanner + '%, 0)'">
          <img class="b-radius width-full" [src]="banner.image" (click)="redirect(banner)">
          <span class="text-s lh-l">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in r</span>
        </div>
      </div>
      <div class="du-flex-align">
        <span class="du-flex-center p-s bg-grey b-radius text-xl du-icon-arrow-big-left"  (click)="bannerPrev()" [ngClass]="activeBanner === 0                ? 'color-disabled' : 'color-blue'" ></span>
        <span class="du-flex-1 du-flex-center text-s">{{ activeBanner+1 }}/{{ banners.length }}</span>
        <span class="du-flex-center p-s bg-grey b-radius text-xl du-icon-arrow-big-right" (click)="bannerNext()" [ngClass]="activeBanner+1 === banners.length ? 'color-disabled' : 'color-blue'" ></span>
      </div>
    </div>
  </div>
</div>
