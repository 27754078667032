import { Injectable } from '@angular/core';
import { TechnicalError, LegalError, ActivityReportLocal, DailyLocal, DailyLocalExtended, DateError } from '@shared/factories';

@Injectable({
  providedIn: 'root'
})

export class ValidationService {
  constructor() { }

  technicalValidation() {
    return ({
      ...this.reportValidation(),
      ...this.dailyValidation(),
      ...this.pauseValidation()
    });
  };

  dailyValidation() {
    return ({
      zeroHoursTimeFrame: ({ daily }: { daily: DailyLocal }) => {
        if (daily.durationExludingPauses <= 0) daily.errors.push(new TechnicalError({ message: 'emptyTimeFrame' }));
      },
      endAfterStartDay: ({ daily }: { daily: DailyLocal }) => {
        if (daily.startsAt.getTime() > daily.endsAt.getTime()) daily.errors.push(new TechnicalError({ message: 'endAfterStartDay', inputEnd: true }));
      },
      oneReportPerDay: ({ daily, dailies }: { daily: DailyLocalExtended, dailies: DailyLocalExtended[] }) => {
        if (dailies) {
          let duplicate = dailies.filter(d => d.assignment.id === daily.assignment.id)
          .filter(d => d.id ? d.id !== daily.id : true)
          .find(d => d.startsAt.getDate() === daily.startsAt.getDate() && d.startsAt.getMonth() === daily.startsAt.getMonth() && d.startsAt.getFullYear() === daily.startsAt.getFullYear());
          if (duplicate) daily.errors.push(new DateError({ message: 'oneReportPerDay' }));
        }
      }
    });
  };

  reportValidation() {
    return ({
      dailyTimeslot: ({ daily, ar, index }: { daily: DailyLocal, index: number, ar: ActivityReportLocal }) => {
        if (ar) {
          let dailyDay     = new Date(daily.startsAt);
          let nextDailyDay = new Date(daily.endsAt);
          dailyDay.setHours(0,0,0,0);
          nextDailyDay.setHours(0,0,0,0);

          let timeslotBooked = ar.activeDailyReports
          .filter((item, aindex) => index !== aindex)
          .filter(item => {
            if (!item.endsAt) return false;
            let itemDay     = new Date(item.startsAt);
            let nextItemDay = new Date(item.endsAt);
            itemDay.setHours(0,0,0,0);
            nextItemDay.setHours(0,0,0,0);

            return itemDay.getTime()     === dailyDay.getTime() || itemDay.getTime()     === nextDailyDay.getTime() ||
                   nextItemDay.getTime() === dailyDay.getTime() || nextItemDay.getTime() === nextDailyDay.getTime();
          }).find(item => (daily.startsAt.getTime() <= item.startsAt.getTime() && daily.endsAt.getTime() >= item.startsAt.getTime()) ||
                          (daily.startsAt.getTime() >= item.startsAt.getTime() && daily.endsAt.getTime() <= item.endsAt.getTime())   ||
                          (daily.startsAt.getTime() <= item.endsAt.getTime()   && daily.endsAt.getTime() >= item.endsAt.getTime())   ||
                          (daily.startsAt.getTime() <= item.startsAt.getTime() && daily.endsAt.getTime() >= item.endsAt.getTime())
          );
          if (timeslotBooked) daily.errors.push(new TechnicalError({ message: 'timeslotBooked' }));
        }
      }
    });
  };

  pauseValidation() {
    return ({
      pauseNotEntered: ({ daily }: { daily: DailyLocal }) => {
        daily.pauses.forEach(pause => {
          if (!pause.start && !pause.end) pause.errors.push(new TechnicalError({ message: 'pausesWithoutStartEnd'                }));
          if (!pause.start &&  pause.end) pause.errors.push(new TechnicalError({ message: 'pausesWithoutStart', inputStart: true }));
          if ( pause.start && !pause.end) pause.errors.push(new TechnicalError({ message: 'pausesWithoutEnd',   inputEnd:   true }));
        });
      },
      zeroMinutePause: ({ daily }: { daily: DailyLocal }) => {
        daily.pauses.forEach((p) => {
          if (p.end.getTime() === p.start.getTime()) p.errors.push(new TechnicalError({ message: 'pauseMin15' }));
        });
      },
      pauseNotWithinDay: ({ daily }: { daily: DailyLocal }) => {
        daily.pauses.forEach(pause => {
          if (pause.start && (daily.startsAt > pause.start || daily.endsAt < pause.start)) pause.errors.push(new TechnicalError({ message: 'pauseNotWithinDay', inputStart: true }));
          if (pause.end   && (daily.startsAt > pause.end   || daily.endsAt < pause.end))   pause.errors.push(new TechnicalError({ message: 'pauseNotWithinDay', inputEnd:   true }));
        });
      },
      inputEndBeforeStart: ({ daily }: { daily: DailyLocal }) => {
        daily.pauses.forEach(pause => {
          if (pause.end < pause.start) pause.errors.push(new TechnicalError({ message: 'pausesEndBeforeStart', inputEnd: true }));
        });
      },
      startPauseInsideOtherPauses: ({ daily }: { daily: DailyLocal }) => {
        let pauses = [...daily.pauses];
        pauses.forEach((pause, pIndex) => {
          pauses.filter((_, lindex) => lindex != pIndex)
          .filter(item => item.start && item.end)
          .forEach(item => {
            if (pause.start.getTime() <= item.start.getTime() && pause.end > item.start) pause.errors.push(new TechnicalError({ message: 'pausesAreOverlappedError', inputStart: true }));
            if (pause.start.getTime() <= item.start.getTime() && pause.end > item.start) pause.errors.push(new TechnicalError({ message: 'pausesAreOverlappedError', inputEnd:   true }));
          });
        });
      },
      endPauseInsideOtherPauses: ({ daily }: { daily: DailyLocal }) => {
        let pauses = [...daily.pauses];

        daily.pauses.forEach((pause, pIndex) => {
          let err = pauses.filter((_, kindex) => kindex != pIndex)
          .find(item =>
            (item.start  < pause.start && item.end  > pause.start) ||
            (item.start  < pause.end   && item.end  > pause.end)   ||
            (pause.start < item.start  && pause.end > item.end)
          );
          if (err) pause.errors.push(new TechnicalError({ message: 'pausesAreOverlappedError', inputEnd: true }));
        });
      }
    })
  };

  legalValidation() {
    return ({
      singlePauseDuration: ({ daily }: { daily: DailyLocal }) => {
        daily.pauses.forEach(pause => {
          let pauseDuration = (pause.end.getTime() - pause.start.getTime()) / 60000;
          if (pause.start && pause.end && pauseDuration < 15) pause.errors.push(new LegalError({ message: 'pauseMin15' }));
        });
      },
      totalPausesTime: ({ daily }: { daily: DailyLocal }) => {
        let workingTime = daily.durationWithPauses - daily.pausesDuration;
        let nineHours = 9*60*60*1000;
        let sixHours  = 6*60*60*1000;
  
        let longPause = 45*60*1000;
        if (workingTime > nineHours && daily.pausesDuration < longPause)
          daily.errors.push(new LegalError({ message: 'workTimeMore9' }));
        
        let shortPause = 30*60*1000;
        if (workingTime <= nineHours && workingTime > sixHours && daily.pausesDuration < shortPause) {
          daily.errors.push(new LegalError({ message: 'workTimeMore6' }));
        }
      },
      maxWorkingHours: ({ daily }: { daily: DailyLocal }) => {
        let maxWorkingHours = 10*60*60*1000; // max 10 hours per day
        if (daily.durationExludingPauses > maxWorkingHours) daily.errors.push(new LegalError({ message: 'workTimeMore10' }));
      }
    });
  }

  validateAR(ar: ActivityReportLocal): void {
    ar.activeDailyReports.forEach((daily, index) => {
      for (let key in this.technicalValidation()) { (this.technicalValidation() as any)[key]({ daily, ar, index }); }
      for (let key in this.legalValidation())     { (this.legalValidation()     as any)[key]({ daily, ar, index }); }
    });
  }

  validateDaily(daily: DailyLocalExtended, dailies: DailyLocalExtended[]): void {
    for (let key in this.technicalValidation()) { (this.technicalValidation() as any)[key]({ daily, dailies }); }
    for (let key in this.legalValidation())     { (this.legalValidation()     as any)[key]({ daily, dailies }); }
  }

}