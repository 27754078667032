<div class="du-flex-column gap-m du-flex-1 overflow-auto" *ngIf="report">
  <ul class="du-flex-column gap-m p-xl">
    <ng-container *ngIf="useCase !== 'mm'"><daily-overview-item [daily]="daily" *ngFor="let daily of activityReport.activeDailyReports"></daily-overview-item></ng-container>
    <ng-container *ngIf="useCase === 'mm'">
      <div *ngFor="let workDay of mileageReport.activeWorkDays" class="du-flex-column gap-s pb-xl bb-grey">
        <div class="du-flex-justify gap-m pt-xs pb-xs bold">
          <span>{{ workDay.date       | date: 'EEEE, dd MMMM YYYY' }}</span>
          <span>{{ workDay.amountOfKm | distanceFormat             }} Km</span>
        </div>
        <div class="du-flex-column gap-xxs">
          <span class="font-s" translate="placeOfDeparture"></span>
          <span>{{ workDay.externalEmployeeAddress }}</span>
        </div>
        <div class="du-flex-column gap-xxs">
          <span class="font-s" translate="destination"></span>
          <span>{{ workDay.assignmentAddress }}</span>
        </div>
      </div>  
    </ng-container>
  </ul>
</div>

<div class="du-flex-column gap-xl pt-s pl-xl pr-xl pb-xl bt-grey" *ngIf="report">
  <ng-container *ngIf="useCase !== 'mm'">
    <div class="du-flex-column gap-s">
      <span class="du-flex-align du-flex-justify">
        <span translate="totalWorkingHours"></span>
        <span class="font-l bold">{{ activityReport.durationWithPauses || 0 | numberToTime }}</span>
      </span>
      <span class="du-flex-align du-flex-justify">
        <span translate="totalWorkingHoursWithoutBreaks"></span>
        <span class="font-l bold">{{ activityReport.durationExludingPauses || 0 | numberToTime }}</span>
      </span>
    </div>  
  </ng-container>

  <ng-container *ngIf="useCase === 'mm'">
    <span class="du-flex-align du-flex-justify">
      <span translate="distance"></span>
      <span class="font-l bold">{{ mileageReport.totalKm || 0 }} km</span>
    </span>
  </ng-container>

  <button class="width-full btn-primary btn-round" translate="next" (click)="callbackHandler()"></button>
</div>
