import { Component, OnInit } from '@angular/core';
import { Router   } from '@angular/router';
import { Location } from '@angular/common';

import { SessionStorageService, DBService, NotificationService } from '@shared/services';
import { DocumentTypeModel } from '@shared/models';
import { DocumentType, PhotoDocumentLocal } from '@shared/factories';

@Component({
  templateUrl: './document-select.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify p-xl gap-l' },
})
export class DocumentSelectComponent implements OnInit {
  document: PhotoDocumentLocal;

  types:    DocumentType[] = [];
  tempType: DocumentType;
  constructor(
    private location:              Location,
    private router:                Router,
    private dbService:             DBService,
    private sessionStorageService: SessionStorageService,
    private notificationService:   NotificationService
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.setHeaderTitle('photoDocument');
    this.sessionStorageService.setHeaderControls({ left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.location.back() }] });
    this.sessionStorageService.setProgressBar(null);
    this.notificationService.wait();
    this.dbService.loadAllFromDB('documentTypes')
    .then((res: DocumentTypeModel[]) => {
      this.types = res.map(a => new DocumentType(a));
    })
    .then(() => {
      this.document = this.sessionStorageService.temporaryPhotoDocument;
      this.tempType = this.document?.docType;
      this.notificationService.close();
    });
  }

  confirmType(): void {
    if (this.document) this.document.docType = this.tempType;
    else this.document = new PhotoDocumentLocal({ docType: this.tempType.toJSON() });
    this.sessionStorageService.setTemporaryValue(this.document);
    this.router.navigate(['time-tracking/photo-report-pd']);
  }

}