<div class="du-flex-column du-flex-1">
  <templates-box *ngIf="useCase === 'ar'"></templates-box>

  <div class="du-flex-column gap-l overflow-auto" *ngIf="vacationInvites && vacationInvites.length">
    <span class="du-flex-justify du-flex-align pl-xl pr-xl pt-m pb-m color-primary bb-grey">
      <span class="font-l lh-l  bold" translate="vacationRequestInvites"></span>
      <span class="du-flex-center du-icon-arrow-down" (click)="showVacationInvites = !showVacationInvites"></span>
    </span>
    <div class="du-flex pl-xl pr-xl" @collapse *ngIf="!showVacationInvites">
      <ul class="du-flex-column gap-m overflow">
        <vacations-overview-item *ngFor="let vacation of vacationInvites" class="shadow" [vacation]="vacation"></vacations-overview-item>
      </ul>
    </div>
  </div>

  <span *ngIf="useCase === 'pd'" class="pl-xl pr-xl pt-xl pb-m color-primary font-l lh-l bb-grey bold" translate="documentCreationPage"></span>
  <span *ngIf="useCase === 'vr'" class="pl-xl pr-xl pt-xl pb-m color-primary font-l lh-l bb-grey bold" translate="vacationRequestCreationPage"></span>
  <span *ngIf="!entries.length"  class="p-xl text-center" translate="noSavedReports">{{ getEmptyEntriesTitle() | translate }}</span>

  <div class="du-flex-column gap-l p-xl overflow-auto">
    <ng-container *ngIf="useCase === 'ar' || useCase === 'pr'">
      <preselect-report-item *ngFor="let report of activityReports"
                             [useCase]="useCase"
                             [report]="report"
                             (submitReport)="submitEntry($event)"
                             (deleteReport)="deleteEntry($event)">
      </preselect-report-item>
    </ng-container>

    <ng-container *ngIf="useCase === 'pd'">
      <document-preselect-item *ngFor="let document of photoDocuments"
                               [document]="document"
                               (submitDocument)="submitEntry($event)"
                               (deleteDocument)="deleteEntry($event)">
      </document-preselect-item>
    </ng-container>

    <ng-container *ngIf="useCase === 'vr'">
      <span *ngFor="let request of vacationRequests">
        {{ request.leaveType }}
      </span>
    </ng-container>
  </div>
</div>

<div class="du-flex-column du-flex-justify gap-m p-xl">
  <button class="width-full btn-primary btn-round" (click)="callbackHandler()">{{ getReportName() | translate }}</button>
</div>
