import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

import { SortModel             } from '@shared/models';
import { SessionStorageService } from '@shared/services';

import * as sortConfig from './sort.config';

@Component({
  selector:      'sort-box',
  templateUrl: './sort-box.component.html',
  host: { class: 'du-flex-center b-radius gap-xs bg-white pt-s pb-s pl-xl pr-xl',
        '[class.color-blue]': 'sortActive',
        "(click)": "openSort()"
   }
})
export class SortBoxComponent implements OnInit, OnDestroy {
  @Input()  useCase: string;
  @Output() updateData = new EventEmitter<void>;

  sort:       SortModel[];
  sortActive: SortModel;
  constructor( private sessionStorageService: SessionStorageService ) {}

  ngOnInit(): void {
    this.sessionStorageService.sortFlow.subscribe(sort => this.sortActive = sort);
  }

  ngOnDestroy(): void {
    this.sessionStorageService.setSortFlow(null);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['useCase'].firstChange || JSON.stringify(changes['useCase'].currentValue) !== JSON.stringify(changes['useCase'].previousValue)) {
      this.prepareDataByUseCase(changes['useCase'].currentValue);
    }
  }

  private prepareDataByUseCase(useCase: string): void {
    this.sort       = (sortConfig as any)[useCase];
    this.sortActive = this.sort.find(s => s.active);
  }

  openSort(): void {
    this.sessionStorageService.pushDynamicComponent({
      component:'Sort',
      props: {
        entries: this.sort,
        save: () => this.updateData.emit()
      }
    });
  }

}
