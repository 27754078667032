import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Release } from '@shared/factories';

import { NotificationService, ReleasesService } from '@shared/services';

@Component({
  selector:      'releases',
  templateUrl: './releases.component.html',
  host: { class: 'height-full du-flex-column gap-xs overflow-auto' }
})
export class ReleasesComponent implements OnInit {
  @Output() loadEntries = new EventEmitter<Function>;
  releases: Release[] = [];
  page:     number;
  constructor(
    private releasesService:     ReleasesService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.loadEntries.emit(this.loadReleases.bind(this));
    this.loadReleases();
  }


  loadReleases(): void {
    this.notificationService.wait();
    this.page = this.page ? this.page+1 : 1;
    this.releasesService.syncReleases(this.page).subscribe(
      releases => {
        this.releases = [...this.releases, ...releases];
        this.notificationService.close();
      },
      err => this.notificationService.alert(err)
    );
  }

}
