<div class="du-flex-justify du-flex-align bb-grey">
  <span class="absolute left-70 right-70 du-flex-center color-grey bold" translate="salaryReports"></span>
  <span class="du-flex-center font-xl du-icon-close pl-xl pr-xl pt-m pb-m mla" (click)="close()"></span>
</div>

<div class="du-flex-column gap-m p-xl">
  <span class="mb-s lh-l" translate="enterBirth"></span>

  <div class="du-flex-column gap-xs">
    <birth-day-input (callback)="birthDayInputHandler($event)"></birth-day-input>
    <span class="font-s" translate="birthRegisteredWithTempton"></span>
  </div>

  <div class="bg-light-blue b-radius lh-l pt-xs pb-xs pl-s pr-s mb-m">
    <span class="font-s mr5" translate="havingTrouble"></span>
    <span class="color-blue bold">{{ userService.currentUserValue.locationPhone || '+49 699 0501 4320' }} </span>
  </div>

  <button class="btn-primary btn-round" translate="next" (click)="submit()" [disabled]="!day || !month || !year"></button>
</div>
