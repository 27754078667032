import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

import { DocumentTypeModel, FilterFlowModel } from '@shared/models';
import { DBService, SessionStorageService } from '@shared/services';
import { DocumentType } from '@shared/factories';

import * as filterConfig from './filter.config';

@Component({
  selector:      'filter-box',
  templateUrl: './filter-box.component.html',
})
export class FilterBoxComponent implements OnInit, OnDestroy {
  @Input()  useCase: string;
  @Output() updateData = new EventEmitter<FilterFlowModel>;

  pills:      any[];
  assignment: boolean;
  from:       string;
  to:         string;

  filterActive: number;
  constructor(
    private sessionStorageService: SessionStorageService,
    private dbService:             DBService
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.filterFlow.subscribe(flow => this.filterActive = flow && +(+!!flow.assignment + +!!flow.from?.date + +!!flow.to?.date + +flow.pills?.length));
  }

  ngOnDestroy(): void {
    this.sessionStorageService.setFilterFlow(null);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['useCase'].firstChange || JSON.stringify(changes['useCase'].currentValue) !== JSON.stringify(changes['useCase'].previousValue)) {
      if (JSON.stringify(changes['useCase'].currentValue === 'pd')) this.loadPhotoDocumentTypes();
      else this.prepareDataByUseCase(changes['useCase'].currentValue);
    }
  }

  private prepareDataByUseCase(useCase: string): void {
    let tempFilter = (filterConfig as any)[useCase];
    if (tempFilter) this.prepareData(tempFilter);
  }

  private prepareData({ pills, assignmentSelect, fromField, toField }: any): void {
    this.pills      = pills;
    this.assignment = assignmentSelect;
    this.from       = fromField;
    this.to         = toField;
  }

  private loadPhotoDocumentTypes(): void {
    this.dbService.loadAllFromDB('documentTypes')
    .then((res: DocumentTypeModel[]) => res.filter(t => t.active !== false).map(a => new DocumentType(a)))
    .then(types => this.prepareData({
      pills: [{
        label: 'Dokumenttyp',
        field: 'subject',
        pills: types.map(t => ({
          label: t.name,
          value: t.name,
        }))
      }]
    }));
  }

  openFilter(): void {
    this.sessionStorageService.pushDynamicComponent({
      component:'Filter',
      props: {
        pills:      this.pills,
        assignment: this.assignment,
        from:       this.from,
        to:         this.to,
        save: () => this.updateData.emit()
      }
    });
  }

}
