import { AssignmentBasicModel, AssignmentModel } from "@shared/models";
import { MileageData, ZipCode, EBS, MileageDataTimeFrame, EBSBasic } from '@shared/factories';

export class AssignmentBasic {
  id:    number;
  title: string;
  constructor(data: AssignmentBasicModel) {
    this.id              = data.id;
    this.title           = data.title;
  }

  toJSON(): AssignmentBasicModel {
    return {
      id:    this.id    ? this.id    : null,
      title: this.title ? this.title : null
    };
  }
}


export class Assignment extends AssignmentBasic {
  userId:           number;
  startsAt:         Date;
  endsAt:           Date;
  createdAt:        Date;
  
  active:           boolean;
  canReleaseLater:  boolean;
  latestEbs:        EBSBasic;
  
  mileageData:      MileageDataTimeFrame[];
  stateISO:         string;
  constructor(data: AssignmentModel, mileageData?: MileageData) {
    super(data);
    this.userId          = data.userId;
    this.startsAt        = this.parceDate(data.starts_at);
    this.endsAt          = this.parceDate(data.ends_at);
    this.createdAt       = this.parceDate(data.created_at);

    this.active          = data.active;
    this.canReleaseLater = !!data.can_release_later;
    this.latestEbs       = data.latest_ebs          ? new EBSBasic(data.latest_ebs) : null;
    
    this.mileageData     = mileageData              ? mileageData.mileageDataTimeFrames : data.mileageData && data.mileageData.length ? data.mileageData.map(md => new MileageDataTimeFrame(md)) : null;
    this.stateISO        = data.zip_code?.state_iso ? data.zip_code.state_iso       : 'null';
  }

  private parceDate(date: any) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  toJSON(): AssignmentModel {
    return Object.assign(super.toJSON(), {
      userId:            this.userId          ? this.userId                             : null,
      starts_at:         this.startsAt        ? this.startsAt.toISOString()             : null,
      ends_at:           this.endsAt          ? this.endsAt.toISOString()               : null,
      created_at:        this.createdAt       ? this.createdAt.toISOString()            : null,

      active:            this.active          ? this.active                             : null,
      can_release_later: this.canReleaseLater ? +this.canReleaseLater                   : null,
      latest_ebs:        this.latestEbs       ? this.latestEbs.toJSON()                 : null,
      
      mileageData:       this.mileageData     ? this.mileageData.map(md => md.toJSON()) : null,
      zip_code:          this.stateISO        ? { state_iso: this.stateISO }            : null,
    });
  }

  get confirmed(): boolean {
    return this.latestEbs ? !!this.latestEbs.confirmedAt : null;
  }

}
