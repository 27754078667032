import { Injectable  } from '@angular/core';
import { formatDate  } from '@angular/common';
import { db          } from '@database';

import { UserModel   } from '@shared/models';
import { UserService } from './user.service';

@Injectable({
  providedIn: "root"
})
export class DBService {
  constructor(
    private userService: UserService
  ) {}

  loadAllFromDB(tableName: string): Promise<any[]> {
    if (!this.userService.currentUserValue) return Promise.reject();
    return Promise.resolve((db as any)[tableName].toArray());
  }

  loadGlobalMultipleFromDB(tableName: string, options: any = null): Promise<any[]> {
    if (!this.userService.currentUserValue) return Promise.reject();
    if (options) return Promise.resolve((db as any)[tableName].where(Object.assign(options)).toArray());
    else         return Promise.resolve((db as any)[tableName].toArray());
  }

  loadMultipleFromDB(tableName: string, options: unknown = null): Promise<any[]> {
    if (!this.userService.currentUserValue) return Promise.reject();
    return Promise.resolve((db as any)[tableName].where(Object.assign({ userId: this.userService.currentUserValue.id }, options)).toArray());
  }

  loadMultipleFromDBPaginated(tableName: string, page: number = 1, filter: unknown = null, sort: any = null): Promise<any> {
    if (!this.userService.currentUserValue) return Promise.reject();
    return Promise.resolve(this.parceQuery(tableName, page, filter, sort));
  }

  loadOneFromDB(tableName: string, options: unknown = null): Promise<any> {
    if (!this.userService.currentUserValue) return Promise.reject();
    return Promise.resolve((db as any)[tableName].get(Object.assign({ userId: this.userService.currentUserValue.id }, options)));
  }

  saveMultipleToDB(tableName: string, entries: unknown[], successMessage: string = ''): Promise<any> {
    return Promise.resolve((db as any)[tableName].bulkPut(entries.map((e: any) => {
      e.userId = this.userService.currentUserValue.id;
      return e.toJSON();
    })))
    .then(() => {
      if (successMessage) console.log(successMessage);
      else console.log(formatDate(new Date(), 'h:mm:ss:SSS dd.MM.yyyy', 'en-US'), '|', `INFO: ${tableName} table updated locally with ${entries.length} entries`);
    });
  }

  saveOneToDB(tableName: string, entry: any, successMessage: string = ''): Promise<number | string> {
    entry.userId = this.userService.currentUserValue.id;
    return Promise.resolve((db as any)[tableName].put(entry.toJSON()))
    .then(primaryKey => {
      if (successMessage) console.log(successMessage);
      else console.log(formatDate(new Date(), 'h:mm:ss:SSS dd.MM.yyyy', 'en-US'), '|', `INFO: ${tableName} table updated locally`);
      return primaryKey;
    });
  }

  deleteOneFromDB(tableName: string, entryId: any, successMessage: string = ''): Promise<any> {
    return Promise.resolve((db as any)[tableName].delete(entryId))
    .then(() => {
      if (successMessage) console.log(successMessage);
      else console.log(formatDate(new Date(), 'h:mm:ss:SSS dd.MM.yyyy', 'en-US'), '|', `INFO: 1 entry with id ${entryId} deleted from ${tableName} table.`);
    });
  }

  clearTable(tableName: string,successMessage: string = ''): Promise<any> {
    return Promise.resolve((db as any)[tableName].clear())
    .then(() => {
      if (successMessage) console.log(successMessage);
      else console.log(formatDate(new Date(), 'h:mm:ss:SSS dd.MM.yyyy', 'en-US'), '|', `INFO: ${tableName} was cleared.`);
    });
  }

  loadLoggedUser(): Promise<UserModel> {
    return db.users.orderBy('id').first();
  }

  private async parceQuery(tableName: string, page: number, query: any, sort: any): Promise<any> {
    let data = await (db as any)[tableName].where('userId').equals(this.userService.currentUserValue.id);
    for (const key in query) {
      for (let i = 0; i < query[key].length; i++) {
        if (query[key][i]?.value) data = await data.and((res: any) => {
          if (key === 'equals')    return this.getNestedValue(res, query[key][i].primKey) === query[key][i].value;
          if (key === 'nullable')  return this.getNestedValue(res, query[key][i].primKey) === null;
          if (key === 'anyOf')     return query[key][i].value.find((el: any) => el === this.getNestedValue(res, query[key][i].primKey));
          if (key === 'anyOfMany') return this.getNestedValue(res, query[key][i].primKey).find((el: any) => query[key][i].value.find((k: any) => k === el));
          if (key === 'belowOrEqual') return this.getNestedValue(res, query[key][i].primKey).getTime() <= query[key][i].value.getTime();
          if (key === 'aboveOrEqual') return this.getNestedValue(res, query[key][i].primKey).getTime() >= query[key][i].value.getTime();
        });
      }
    }

    if (sort?.primKey)        data = await data.sortBy(sort.primKey);
    if (sort?.dir === 'desc') data = data.reverse();

    return {
      paging: {
        current_page: page,
        per_page:     50,
        total_count:  data.length,
        total_pages:  Math.ceil(data.length/50)
      },
      data: data.slice((page-1) * 50, 50)
    };
  }

  private getNestedValue(obj: object, key: string) {
    return key.split('.').reduce((p: any, c: string) => p?.[c], obj);
  }

}
