import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ActivityReportLocal, DailyLocal, MileageReportLocal, MileageWorkDayLocal } from '@shared/factories';
import { fadeIn } from '@shared/animations';

@Component({
  selector:      'day-selector',
  templateUrl: './day-selector.component.html',
  host: { class: 'du-flex-justify gap5 font-12' },
  animations: [ fadeIn ]
})
export class DaySelectorComponent {
  @Input() entry:            ActivityReportLocal | MileageReportLocal;
  @Input() activeEntryIndex: number;
  @Input() useCase:          string;

  @Output() daySelectCallback = new EventEmitter<number>;
  constructor( ) { }

  get entries(): (DailyLocal | MileageWorkDayLocal)[] {
    return (this.entry as ActivityReportLocal).dailyReports || (this.entry as MileageReportLocal).workDays || [];
  }

  getEntryDate(entry: DailyLocal | MileageWorkDayLocal): Date {
    return (entry as DailyLocal).startsAt || (entry as MileageWorkDayLocal).date;
  }

  dayIsDisabled(entry: DailyLocal | MileageWorkDayLocal): boolean {
    let day = new Date(this.getEntryDate(entry));
    day.setHours(0,0,0);
    return day < this.entry.startDate || day > this.entry.endDate || day > new Date();
  }

}
