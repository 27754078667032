import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { from, map, of, switchMap } from 'rxjs';

import { PagingModel } from '@shared/models';
import { ActivityReportLocal, ActivityReportOverview } from '@shared/factories';
import { ActivityReportsService, DBService, NotificationService, SessionStorageService } from '@shared/services';

@Component({
  selector:      'reports-overview',
  templateUrl: './reports-overview.component.html',
  host: { class: 'height-full du-flex-column gap-m overflow-auto' }
})
export class ReportsOverviewComponent implements OnInit {
  @Output() loadEntries = new EventEmitter<Function>;
  page:     number;
  paging:   PagingModel;
  reports: (ActivityReportOverview | ActivityReportLocal)[] = [];
  constructor(
    private dbService:              DBService,
    private activityReportsService: ActivityReportsService,
    private notificationService:    NotificationService,
    private sessionStorageService:  SessionStorageService
  ) {}

  ngOnInit(): void {
    this.loadEntries.emit(this.loadReports.bind(this));
    this.sessionStorageService.paging.subscribe(paging => this.paging = paging);
    this.loadReports();
  }

  loadReports(reload: boolean = null): void {
    if (!this.paging || !this.paging.total_pages || !this.page || this.page < this.paging.total_pages || reload) {
      this.page = reload || !this.page ? 1 : this.page+1;
      this.notificationService.wait();
      this.activityReportsService.loadReports(this.page).pipe(
        switchMap(reports => {
          if (this.page === 1) return from(this.dbService.loadMultipleFromDB('pendingUpload', { useCase: 'ar' })).pipe(
            map(pendingAr => pendingAr.map(vr => new ActivityReportLocal(vr))),
            map(pendingAr => [...pendingAr, ...reports])
          );
          else return of(reports);
        })
      ).subscribe(
        reports => {
          if (reload) this.reports = [...reports];
          else this.reports = [...this.reports, ...reports];
          this.sessionStorageService.setCachedEntries(this.reports);
          this.notificationService.close();
        },
        err => this.notificationService.alert(err),
        () => this.sessionStorageService.setPulltoRefreshState(true)
      );
    }
  }

}
