import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { slideUp  } from '@shared/animations';
import { ActivityReportLocal, Template } from '@shared/factories';
import { SessionStorageService } from '@shared/services';

@Component({
  selector:      'template-details',
  templateUrl: './template-details.component.html',
  host: { class: 'height-max-full-xl absolute bottom-0 left-0 right-0 du-flex-column btl-radius btr-radius absolute bg-white z-2' },
  animations: [ slideUp ]
})
export class TemplateDetailsComponent implements OnInit, OnDestroy {
  @Input() template: Template;
  private readonly COMPONENT_NAME: string = 'TemplateDetails';
  @HostBinding('@slideUp') get slideIn() { return 'open'; }
  constructor(
    private router:                Router,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.pushOverflowStack(this.COMPONENT_NAME);
  }

  ngOnDestroy(): void {
    this.sessionStorageService.popOverflowStack(this.COMPONENT_NAME);
  }

  close(): void {
    this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME)
  }

  useTemplate(): void {
    this.sessionStorageService.setTemporaryValue(new ActivityReportLocal({
      dailyReports: this.template.dailies.map(d => d.toJSON())
    }));
    this.router.navigateByUrl('/time-tracking/assignment-select-ar-t');
    this.close();
  }

}
