import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { Observable, from, map, take, tap } from 'rxjs';

import { environment  } from 'environments/environment';
import { Holiday, HolidayExtended } from '@shared/factories';
import { HolidayModel } from '@shared/models';
import { DBService    } from './db.service'

interface HolidaysResponse { holidays: HolidayModel[]; }

@Injectable({
  providedIn: 'root'
})

export class HolidaysService {
  private readonly HOLIDAYS_API = `${environment.apiUrl}/api/mobile/v3/holidays`;
  private readonly DB_TABLE     = 'holidays';

  constructor(
    private http:      HttpClient,
    private dbService: DBService,
  ) { }

  private loadHolidays(): Observable<Holiday[]> {
    let from = new Date(new Date().getTime() - 8*7*24*60*60*1000); // 8 weeks in past
    let to   = new Date(new Date().getTime() + 5*7*24*60*60*1000); // 5 weeks in future
    return this.http.get<HolidaysResponse>(`${this.HOLIDAYS_API}?q[date][from]=${formatDate(from, 'yyyy-MM-dd', 'de')}&q[date][to]=${formatDate(to, 'yyyy-MM-dd', 'de')}`).pipe(
      take(1),
      map(res => res.holidays),
      map(holidays => holidays.map(h => new Holiday(h)))
    );
  }

  syncHolidays(): Observable<Holiday[]> {
    return this.loadHolidays().pipe(
      tap(res => from(this.dbService.clearTable(this.DB_TABLE))),
      tap(res => this.dbService.saveMultipleToDB(this.DB_TABLE, res)
    ));
  }

  holidaysListMappedByStates(): Observable<HolidayExtended[]> {
    return from(this.dbService.loadGlobalMultipleFromDB(this.DB_TABLE)).pipe(
      map(res => {
        let temp: any = {};

        res.forEach(h => {
          if (!temp[h.name])          temp[h.name] = h;
          if (h.state_iso === 'null') h.state_iso  = null;

          if      (!temp[h.name].states)                                                     temp[h.name].states = h.state_iso ? [h.state_iso] : ['global'];
          else if ( temp[h.name].states.find((s: string) => s === 'global') || !h.state_iso) temp[h.name].states = ['global'];
          else                                                                               temp[h.name].states.push(h.state_iso);
        });

        for (const key in temp) {
          if      (temp[key].states.find((s: string) => s === 'global')) temp[key].states = null;
          else if (temp[key].states.length)                              temp[key].states = temp[key].states.join(', ');
        }

        return Object.values(temp).map((h: any) => new HolidayExtended(h));
      })
    );
  }

}
