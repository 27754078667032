<div class="du-flex-justify du-flex-align bb-grey">
  <span class="absolute left-70 right-70 du-flex-center color-grey bold" translate="vacationRequest"></span>
  <img class="pl-xl pr-xl pt-m pb-m mla " src="assets/svg/close.svg" (click)="close()">
</div>

<div class="du-flex-column du-flex-1 gap-xl p-xl"> 
  <div class="du-flex-column gap-s">
    <span class="color-disabled bold" translate="vacationType"></span>
    <div class="select-wrapper disabled">
      <select [(ngModel)]="vacation.leaveType" disabled="true" class="grey">
        <option   [value]="vacation.leaveType" disabled selected>{{ vacation.leaveType | translate }}</option>
      </select>
    </div>
  </div>

  <div class="du-flex-justify">
    <div class="du-flex-column gap-s"><span class="color-disabled bold" translate="from"></span><input class="date-input grey" type="date" [ngModel]="vacation.startsOn | date:'yyyy-MM-dd'" disabled></div>
    <div class="du-flex-column gap-s"><span class="color-disabled bold" translate="to"  ></span><input class="date-input grey" type="date" [ngModel]="vacation.endsOn   | date:'yyyy-MM-dd'" disabled></div>
  </div>

  <div class="du-flex-column gap-m mta">
    <span class="bg-grey b-radius font-s lh-l bold p-s" translate="vacationRequestsCheckDetails"></span>

    <div class="du-flex-justify gap-s">
      <button class="btn-reject  du-flex-1" translate="reject"  (click)="updateVacationInvite('rejected')"></button>
      <button class="btn-primary du-flex-1" translate="confirm" (click)="updateVacationInvite('approved')"></button>
    </div>
  </div>
</div>
