import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VacationRequestLocal, VacationRequestOverview } from '@shared/factories';

@Component({
  selector:      'vacations-overview-item',
  templateUrl: './vacations-overview-item.component.html',
  host: { class: 'height-min-fit-content bg-white shadow-header overflow b-radius' }
})
export class VacationsOverviewItemComponent {
  @Input() vacation: VacationRequestOverview | VacationRequestLocal;
  @Input() extended: boolean;
  @Output() close = new EventEmitter<void>;
  constructor() {}

  getLocalVacation():    VacationRequestLocal    { return this.vacation as VacationRequestLocal;    }
  getOverviewVacation(): VacationRequestOverview { return this.vacation as VacationRequestOverview; }

  getStateColor(): string {
    if ( this.getOverviewVacation().internalReview === 'approved')      return 'green';
    if (!this.getOverviewVacation().internalReview && this.vacation.id) return 'orange';
    if ( this.getOverviewVacation().internalReview === 'rejected')      return 'red';
    return 'blue';
  }

}
