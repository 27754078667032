<div class="du-flex-column gap-xxl p-xl" *ngIf="entry">
  <div class="du-flex-column gap-m">
    <span class="font-l bold" translate="releaseOptions"></span>
    <span class="du-flex-align b-radius pt-s pb-s pl-m pr-m lh-l" 
          [ngClass]="entry.signedByCustomer ? 'bg-blue color-white' : 'bg-card-grey'"
          (click)="selectLifeState(1)"
          translate="approvalWithSignature">
    </span>
    <span class="du-flex-align b-radius pt-s pb-s pl-m pr-m lh-l" *ngIf="entry && entry.assignment.canReleaseLater"
          [ngClass]="entry.waitingCustomerApproval ? 'bg-blue color-white' : 'bg-card-grey'"
          (click)="selectLifeState(2)"
          translate="willApproveLater">
    </span>
    <span class="du-flex-align b-radius pt-s pb-s pl-m pr-m lh-l" 
          [ngClass]="entry.waitingClarification ? 'bg-blue color-white' : 'bg-card-grey'"
          (click)="selectLifeState(3)"
          translate="needClarification">
    </span>
  </div>

  <div class="du-flex-column gap-xs">
    <span translate="noteToManagerOpt"></span>
    <textarea class="height-60" cols="30" rows="3" class="p-xs b-grey" placeholder="{{ 'messageToOffice' | translate }}" [(ngModel)]="getReport().externalNote"></textarea>
  </div>
</div>

<div class="du-flex-column du-flex-justify du-flex-1 gap-m pt-m pl-xl pr-xl pb-xl bt-grey">
  <div class="du-flex-column gap-m" *ngIf="entry.signedByCustomer">
    <div class="du-flex-column gap-s">
      <div class="du-flex-align gap-xs p-m shadow-header b-radius" (click)="openSignatureBox()" [class.b-red]="checkSignature && !entry.signature" [class.b-grey]="!checkSignature || entry.signature">
        <img src="assets/svg/signature.svg">
        <span translate="customerSignature"></span>
        <div class="du-flex-align gap-s mla">
          <span class="color-blue bold" translate="sign" *ngIf="!entry.signature"></span>
          <img src="assets/svg/tick-green.svg" *ngIf="entry.signature">
          <span class="color-green bold" translate="signed" *ngIf="entry.signature"></span>
        </div>
      </div>
      <span class="font-s color-red" *ngIf="checkSignature && !entry.signature" translate="provideCustomerSignature"></span>
    </div>

    <div class="du-flex-align gap-xs p-m shadow" *ngIf="useCase !== 'vr' && entry.signature" (click)="openCustomerEmailBox()">
      <img src="assets/svg/envelope-blue.svg">
      <span translate="sendCopyByEmail"></span>
      <img class="mla" src="assets/svg/square.svg"           *ngIf="!getReport().customerEmails || !getReport().customerEmails.length">
      <img class="mla" src="assets/svg/square-tick-blue.svg" *ngIf=" getReport().customerEmails &&  getReport().customerEmails.length">
    </div>
  </div>

  <div class="du-flex-column gap-m mta">
    <div class="du-flex-align-start gap-xs p-s bg-light-orange b-radius" *ngIf="entry && entry.lifeState && entry.lifeState !== 'signed_by_customer'">
      <img src="assets/svg/warning.svg">
      <span class="font-s lh-l" translate="informationWillBeChecked"></span>
    </div>

    <div class="du-flex-align-start gap-xs p-s bg-light-orange b-radius" *ngIf="useCase === 'vr'">
      <img src="assets/svg/warning.svg">
      <span class="font-s lh-l" translate="vacationNoLongerBeChanged"></span>
    </div>

    <button class="btn-secondary btn-round" translate="save"   [disabled]="!readyForSubmit()" (click)="saveEntry()"></button>
    <button class="btn-primary   btn-round" translate="submit" [disabled]="!readyForSubmit()" (click)="submitEntry()"></button>
  </div>
</div>
