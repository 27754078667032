import { ValidationErrorModel } from "@shared/models";

export class ValidationError {
  message:    string;

  inputStart: boolean;
  inputEnd:   boolean;
  constructor(data: ValidationErrorModel) {
    this.message    = data.message;

    this.inputStart = data.inputStart;
    this.inputEnd   = data.inputEnd;
  }
}

export class TechnicalError extends ValidationError { }
export class DateError      extends ValidationError { }
export class LegalError     extends ValidationError { }
