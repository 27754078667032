import { Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { DBService, SessionStorageService, UserService, ValidationService } from '@shared/services';
import { Assignment, DailyLocalExtended, Holiday } from '@shared/factories';
import { slideUp } from '@shared/animations';

@Component({
  selector:      'daily-report-constructor',
  templateUrl: './daily-report-constructor.component.html',
  host: { class: 'height-max-700-auto absolute bottom-0 left-0 right-0 du-flex-column btl-radius btr-radius absolute bg-white'},
  animations: [ slideUp ]
})
export class DailyReportConstructorComponent implements OnInit, OnDestroy {
  dailies:  DailyLocalExtended[];
  daily:    DailyLocalExtended;
  date:     Date = new Date();
  holidays: Holiday[];
  @Output() callback = new EventEmitter<DailyLocalExtended>;
  @HostBinding('@slideUp') get slideIn() { return 'open'; }
  private readonly COMPONENT_NAME: string = 'DailyReport';
  constructor(
    private router:                Router,
    private dbService:             DBService,
    private userService:           UserService,
    private validationService:     ValidationService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    if (!this.userService.checkTutorial('dr')) setTimeout(() => this.sessionStorageService.pushDynamicComponent({
      component: 'TutorialScreen',
      props: { useCase: 'dr' }
    }));

    this.sessionStorageService.pushOverflowStack(this.COMPONENT_NAME);
    this.dbService.loadMultipleFromDB('localDailies')
    .then(dailies => this.dailies = dailies.map(d => new DailyLocalExtended(d)))
    .then(() => {
      if (!this.daily) this.prepareDefaultDaily();
      else this.validate();
    });
  }

  ngOnDestroy(): void {
    this.sessionStorageService.popOverflowStack(this.COMPONENT_NAME);
  }

  close(): void {
    this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME);
  }

  prepareDefaultDaily(): void {
    let day = new Date(this.date);
    day.setHours(0,0,0,0);
    this.daily = new DailyLocalExtended({
      started_at: new Date(day.getFullYear(), day.getMonth(), day.getDate(), 8,  0, 0),
      ended_at:   new Date(day.getFullYear(), day.getMonth(), day.getDate(), 16, 0, 0),
      pauses: [{
        id: 1,
        start: new Date(day.getFullYear(), day.getMonth(), day.getDate(), 12,  0, 0),
        end:   new Date(day.getFullYear(), day.getMonth(), day.getDate(), 12, 30, 0),
      }]
    });
  }

  async adjustAssignment(assignment: Assignment) {
    this.daily.assignment = assignment;
    this.holidays         = await this.dbService.loadGlobalMultipleFromDB('holidays', { state_iso: this.daily.assignment.stateISO }).then(holidays => holidays.map(h => new Holiday(h)));

    this.checkExtraData();
    this.daily = new DailyLocalExtended(this.daily.toJSON());
    this.validate();
  }

  adjustDates(dateString: string): void {
    this.date = new Date(dateString);
    this.daily.startsAt = this.adjustDate(this.daily.startsAt, this.date);
    this.daily.endsAt   = this.adjustDate(this.daily.endsAt,   this.date);
    this.daily.pauses.forEach(p => {
      p.start = this.adjustDate(p.start, this.date);
      p.end   = this.adjustDate(p.end,   this.date);
    });
    this.checkExtraData();
    this.daily = new DailyLocalExtended(this.daily.toJSON());
  }

  private adjustDate(times: Date, dates: Date): Date {
    let date = new Date(dates);
    date.setHours(times.getHours(), times.getMinutes(), 0, 0);
    return date;
  }

  private checkExtraData(): void {
    this.daily.holidays = this.holidays?.filter(h => h.date.getDate()     === this.daily.startsAt.getDate()  && 
                                                    h.date.getMonth()    === this.daily.startsAt.getMonth() &&
                                                    h.date.getFullYear() === this.daily.startsAt.getFullYear());
    this.daily.mileage  = !!this.daily.assignment.mileageData?.find(md => md.startsAt.getTime() <= this.daily.startsAt.getTime() &&
                                                                         md.endsAt.getTime()   >= this.daily.startsAt.getTime());
    setTimeout(() => this.validate());
  }

  adjustDaily({ daily }: any): void {
    this.daily = daily;
    this.validate();
  }

  validate(): void {
    this.cleanErrors();
    this.validationService.validateDaily(this.daily, this.dailies);
  }

  private cleanErrors(): void {
    this.daily.errors = [];
    if (this.daily.pauses && this.daily.pauses.length) this.daily.pauses.forEach(p => { p.errors = []; });

    this.daily.endsAt.setDate(this.daily.startsAt.getDate());
    this.daily.endsAt.setMonth(this.daily.startsAt.getMonth());
    if (this.daily.endsAt < this.daily.startsAt) this.daily.endsAt.setDate(this.daily.startsAt.getDate() + 1);
  }

  save(): void {
    this.dbService.saveOneToDB('localDailies', this.daily)
    .then(id => {
      this.callback.emit(Object.assign(this.daily, { id }));
      this.router.navigateByUrl('time-tracking/dailies-overview');
      this.close();
    });
  }

}
