<div class="du-flex-column gap-xs pl-m pt-m pr-m pb-xs bb-grey">
  <div class="du-flex gap-xl relative">
    <span class="du-flex-align gap5 bold">
      <span>{{ daily.startsAt | date: 'EEE - ' }}</span>
      <span class="roboto">{{ daily.startsAt | date: 'dd.MM.YYYY' }}</span>
    </span>
    <daily-tooltips [errors]="getErrors() && !!getErrors().size" [mileage]="getMileage()" [holidays]="daily.holidays"></daily-tooltips>
    <img class="width-20" src="assets/svg/more-blue.svg" *ngIf="allowEdit" (click)="toggleOptions()">
    <div class="absolute right-0 top-0 mt-xl du-flex-column b-radius bg-card-grey" *ngIf="optionsOpen">
      <span class="du-flex-align gap-s pt-s pb-s pl-m pr-m bb-grey hover-active" (click)="editDaily()">
        <img src="assets/svg/edit-black.svg">
        <span translate="edit"></span>
      </span>
      <span class="du-flex-align gap-s pt-s pb-s pl-m pr-m hover-active" (click)="deleteReportPrompt()">
        <img src="assets/svg/bin-black.svg">
        <span translate="delete"></span>
      </span>
    </div>
  </div>
  <span class="du-flex-justify">
    <span class="color-primary-75" translate="workingHours"></span>
    <span class="roboto bold">{{ daily.durationWithPauses | numberToTime }}</span>
  </span>
  <span class="du-flex-justify">
    <span class="color-primary-75" translate="workingHoursWithoutBreaks"></span>
    <span class="roboto bold">{{ daily.durationExludingPauses | numberToTime }}</span>
  </span>
</div>
<div class="du-flex-column gap-s p-m" (click)="collapsed = !collapsed">
  <div class="du-flex-justify color-blue font-s">
    <span *ngIf="!collapsed" translate="showMore"></span>
    <span *ngIf="collapsed" translate="showLess"></span>
    <img  src="assets/svg/arrow-down-blue.svg" [class.rotate]="collapsed">
  </div>
  <div class="du-flex-column" *ngIf="collapsed" @collapse>
    <div class="du-flex-column du-flex-justify-end gap-s overflow">
      <div class="du-flex-column gap-xxs">
        <span class="font-s" translate="project"></span>
        <span class="bold">{{ daily.project || '-' }}</span>
      </div>
      <div class="du-flex-justify">
        <div class="du-flex-column du-flex-1 gap-xxs">
          <span class="font-s" translate="breaks"></span>
          <span class="roboto bold" *ngFor="let pause of daily.pauses">
            {{ pause.start | date: 'HH:mm' }} - {{ pause.end | date: 'HH:mm' }}
          </span>
        </div>
        <div class="du-flex-column du-flex-1 gap-xxs">
          <span class="font-s" translate="totalBreakTime"></span>
          <span class="roboto bold">{{ daily.pausesDuration | numberToTime }}</span>
        </div>
      </div>
      <div class="du-flex-align-start gap-xs p-s bg-light-orange b-radius" *ngIf="getErrors() && getErrors().size">
        <img src="assets/svg/warning.svg">
        <span class="du-flex-column gap-xs font-s">
          <span *ngFor="let err of getErrors()">{{ err | translate }}</span>
        </span>
      </div>
    </div>
  </div>
</div>
