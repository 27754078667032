import { Component, Input } from '@angular/core';
import { PhotoDocument, PhotoDocumentLocal } from '@shared/factories';
import { ImageService, NotificationService } from '@shared/services';

@Component({
  selector:      'documents-overview-item',
  templateUrl: './documents-overview-item.component.html',
  host: { class: 'height-min-fit-content du-flex-column gap-s bg-white p-s shadow-header overflow b-radius' }
})
export class DocumentsOverviewItemComponent {
  @Input() document: PhotoDocument | PhotoDocumentLocal;
  collapsed:         boolean;
  extendImage:       boolean;
  loaded:            boolean;
  src:               string = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP88PHbfwAJiAPYEEGTwgAAAABJRU5ErkJggg==';
  constructor(
    private imageService: ImageService,
    public  notificationService: NotificationService,
  ) {}

  collapsePD(): void {
    if (!this.collapsed && !this.document.attachment && (this.document as PhotoDocument).attachmentUrl) {
      this.notificationService.wait();
      this.imageService.loadImage((this.document as PhotoDocument).attachmentUrl).subscribe(
        attachment => {
          this.document.attachment = attachment as string;
          this.loaded = true;
        },
        err => {
          console.error(err);
          this.loaded = true;
          this.notificationService.close();
        }
      );
    } else this.loaded = false;
    this.collapsed = !this.collapsed;
  }

  attachmentLoaded(): void {
    if (this.document.attachment) this.notificationService.close();
  }

  getLocalDocument(): PhotoDocumentLocal {
    return this.document as PhotoDocumentLocal;
  }

  getOverviewDocument(): PhotoDocument {
    return this.document as PhotoDocument;
  }

}
