<div class="du-flex-align" [ngClass]="'bl-' + getStateColor() + '-5'">
<div class="du-flex-column du-flex-1 gap-xs p-s">
  <div class="du-flex-justify du-flex-align">
    <span class="du-flex-align font-600 gap-xxs">
      <span translate="cw"></span>
      <span>{{  report.startDate | date: 'ww' }}</span>
      <span>({{ report.startDate | date: 'dd' }}.{{ report.startDate | date: 'MM' }} - {{ report.endDate | date: 'dd' }}.{{ report.endDate | date: 'MM' }})</span>
    </span>

    <span *ngIf="getLocalReport().notSynced" class="du-flex-align gap-xs pt-xs pb-xs pl-s pr-s b-radius bg-light-orange color-orange">
      <span class="du-flex-center du-icon-offline font-l"></span>
      <span class="font-s" translate="notSynced"></span>
    </span>
    <span *ngIf="getOverviewReport().customerSignature" class="du-flex-align gap-xs pt-xs pb-xs pl-s pr-s b-radius bg-green-light color-green">
      <span class="du-flex-center du-icon-tick font-l"></span>
      <span class="font-s" translate="signed"></span>
    </span>
    <span *ngIf="getOverviewReport().createdByTempton" class="pt-xs pb-xs pl-s pr-s b-radius font-600 bg-light-blue color-blue">T</span>
  </div>

  <span class="lh-l color-primary">{{ report.assignment.title }}</span>

  <div class="du-flex-align du-flex-1 gap-xs">
    <span class="du-flex-center p-xxs b-radius bg-light-blue color-blue font-icon du-icon-envelope"    *ngIf="getOverviewReport().confirmationEmails"></span>
    <span class="du-flex-center p-xxs b-radius bg-light-blue color-blue font-icon du-icon-edited"      *ngIf="getOverviewReport().reportCorrected && !getOverviewReport().attachmentPath"></span>
    <span class="du-flex-center p-xxs b-radius bg-light-blue color-blue font-icon du-icon-holiday-sun" *ngIf="getOverviewReport().haveHolidays"></span>
    <span class="du-flex-center p-xxs b-radius bg-light-blue color-blue font-icon du-icon-camera"      *ngIf="getOverviewReport().attachmentPath"></span>
    <span class="du-flex-center p-xxs b-radius bg-light-blue color-blue font-icon du-icon-mileage"     *ngIf="getOverviewReport().mileageMoneyReportId"></span>
    <span class="du-flex-center p-xxs b-radius bg-light-blue color-blue font-icon du-icon-mileage"     *ngIf="getOverviewReport().splitParentId || getOverviewReport().splitChildId"></span>
  </div>
</div>
