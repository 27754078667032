import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MileageWorkDayLocal } from '@shared/factories';
import { collapse, fadeIn    } from '@shared/animations';

@Component({
  selector:      'constructor-work-day',
  templateUrl: './constructor-work-day.component.html',
  host: { class: 'du-flex-column b-radius shadow relative' },
  animations: [ fadeIn, collapse ]
})
export class MileageWorkDayComponent {
  @Input() index:   number;
  @Input() workDay: MileageWorkDayLocal;

  @Output() resetToDefault = new EventEmitter<any>;
  constructor() {}

  resetPlaceholder(): void {
    this.resetToDefault.emit({ index: this.index, workDay: this.workDay });
  }

  addDefaultDay() {
    this.workDay.placeholder = false;
  }

  validateDistance(workDay: MileageWorkDayLocal): void {
    if (!workDay.amountOfKm) workDay.amountOfKm = 1;
  }

}
