import { FilterConfigModel } from "@shared/models";

export const ar: FilterConfigModel = {
  pills: [
    { label: 'specifications', field: 'additional_info', pills: [
      { label: 'millageAllowance',  value: 'withMileageMoney'  },
      { label: 'withHolidays',      value: 'withHolidays'      },
      { label: 'photoReport',       value: 'withAttachment'    },
      { label: 'regularReport',     value: 'notAttachment'     },
      { label: 'withSplitReport',   value: 'withSplitReport'   },
      { label: 'withCustomerEmail', value: 'withCustomerEmail' },
      { label: 'withReleasedState', value: 'withReleasedState' },
    ]}
  ],
  fromField:       'date_from',
  toField:         'date_to',
  assignmentSelect: true
};

export const mm: FilterConfigModel = {
  pills: [
    { label: 'resourceType', field: 'resource_type', pills: [
      { label: 'Manuell',    value: 'standalone_mileage_report'     },
      { label: 'TN bezogen', value: 'working_period_mileage_report' }
    ]}
  ],
  fromField:       'start_date',
  toField:         'end_date',
  assignmentSelect: true
};

export const vr: FilterConfigModel = {
  pills: [
    { label: 'leaveType', field: 'leave_type', pills: [
      { label: 'annual',  value: 'annual'  },
      { label: 'special', value: 'special' },
      { label: 'unpaid',  value: 'unpaid'  }
    ]},
    { label: 'reason', field: 'reason', pills: [
      { label: 'wedding_birth',            value: 'wedding_birth'            },
      { label: 'death_of_close_relatives', value: 'death_of_close_relatives' },
      { label: 'death_of_relatives',       value: 'death_of_relatives'       }
    ]}
  ],
  fromField:       'starts_on',
  toField:         'ends_on',
  assignmentSelect: true
};

export const pd: FilterConfigModel = {
  fromField: 'created_at_from',
  toField:   'created_at_to'
};
