import { Component, OnInit      } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, defer, from, map, of, switchMap, take, tap } from 'rxjs';

import { ActivityReportLocal, PhotoDocument, PhotoDocumentLocal, VacationRequestLocal } from '@shared/factories';
import { ActivityReportLocalModel, PhotoDocumentLocalModel, VacationRequestLocalModel } from '@shared/models';
import { ActivityReportsService, DBService, NotificationService, PhotoDocumentsService, SessionStorageService, UserService, VacationRequestsService } from '@shared/services';
import { collapse } from '@shared/animations';

@Component({
  templateUrl: './preselect-report.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify' },
  animations: [collapse]
})
export class PreselectReportComponent implements OnInit {
  useCase:             string;
  entries:            (ActivityReportLocal | PhotoDocumentLocal | VacationRequestLocal)[] = [];

  vacationInvites:     VacationRequestLocal[] = [];
  showVacationInvites: boolean;
  constructor(
    private route:                   ActivatedRoute,
    private router:                  Router,
    private dbService:               DBService,
    private activityReportsService:  ActivityReportsService,
    private photoDocumentsService:   PhotoDocumentsService,
    private vacationRequestsService: VacationRequestsService,
    private userService:             UserService,
    private sessionStorageService:   SessionStorageService,
    private notificationService:     NotificationService
  ) {}

  ngOnInit(): void {
    this.useCase = this.route.snapshot.data['useCase'];
    this.sessionStorageService.setHeaderControls({ left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.router.navigateByUrl('/time-tracking/home')}] });
    this.sessionStorageService.setProgressBar(null);
    this.sessionStorageService.setTemporaryValue(null);
    this.prepareReports();

    if (!this.userService.checkTutorial(this.useCase)) this.sessionStorageService.pushDynamicComponent({
      component: 'TutorialScreen',
      props: { useCase: this.useCase }
    });
  }

  private prepareReports(): void {
    this.prepareHeader();

    this.notificationService.wait();
    defer(() => from(this.dbService.loadMultipleFromDB(this.dbTable))).pipe(
      tap(entries => this.prepareEntries(entries)),
      switchMap(() => this.loadVacationInvites())
    ).subscribe(() => this.notificationService.close());
  }

  private loadVacationInvites(): any {
    if (this.useCase !== 'vr') return of(null);

    let filter: any = {
      state:              'awaiting_external_approval',
      creation_initiator: 'internal'
    };
    return defer(() => from(this.dbService.loadMultipleFromDB('vacationRequests', filter))).pipe(
      take(1),
      map((invites: VacationRequestLocalModel[]) => this.prepareVacationRequest(invites)),
      tap(invites => this.vacationInvites = invites)
    );
  }

  private prepareHeader(): void {
    if (this.useCase === 'ar') this.sessionStorageService.setHeaderTitle('activityReports');
    if (this.useCase === 'pr') this.sessionStorageService.setHeaderTitle('photoReports');
    if (this.useCase === 'pd') this.sessionStorageService.setHeaderTitle('photoDocument');
    if (this.useCase === 'vr') this.sessionStorageService.setHeaderTitle('vacationRequests');
  }

  private prepareEntries(entries: ActivityReportLocalModel[] | PhotoDocumentLocalModel[] | VacationRequestLocalModel[]): void {
    if (this.useCase === 'ar') this.entries = this.prepareActivityReports(entries as ActivityReportLocalModel[]);
    if (this.useCase === 'pr') this.entries = this.prepareActivityReports(entries as ActivityReportLocalModel[]);
    if (this.useCase === 'pd') this.entries = this.preparePhotoDocuments( entries as PhotoDocumentLocalModel[]);
    if (this.useCase === 'vr') this.entries = this.prepareVacationRequest(entries as VacationRequestLocalModel[]);
  }

  private prepareActivityReports(entries: ActivityReportLocalModel[]): ActivityReportLocal[] {
    return entries.map(r => new ActivityReportLocal(r)).sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
  }

  private preparePhotoDocuments(entries: PhotoDocumentLocalModel[]): PhotoDocumentLocal[] {
    return entries.map(d => new PhotoDocumentLocal(d));
  }

  private prepareVacationRequest(entries: VacationRequestLocalModel[]): VacationRequestLocal[] {
    return entries.map(d => new VacationRequestLocal(d));
  }

  get activityReports():  ActivityReportLocal[]  { return this.entries as ActivityReportLocal[];  }
  get photoDocuments():   PhotoDocumentLocal[]   { return this.entries as PhotoDocumentLocal[];   }
  get vacationRequests(): VacationRequestLocal[] { return this.entries as VacationRequestLocal[]; }

  submitEntry(entry: ActivityReportLocal | PhotoDocumentLocal | VacationRequestLocal): void {
    this.notificationService.wait();
    let call;
    if (this.useCase === 'ar') call = this.submitActivityReport( entry as ActivityReportLocal);
    if (this.useCase === 'pr') call = this.submitActivityReport( entry as ActivityReportLocal);
    if (this.useCase === 'pd') call = this.submitPhotoDocument(  entry as PhotoDocumentLocal);
    if (this.useCase === 've') call = this.submitVacationRequest(entry as VacationRequestLocal);

    call.subscribe(
      res => {
        this.deleteEntry(res.id);
        this.router.navigate(['time-tracking/info'], { queryParams: { type: 'submit', useCase: this.useCase }});
      },
      err => this.notificationService.alert(err)
    );
  }

  private submitActivityReport(report: ActivityReportLocal): Observable<any> {
    return this.activityReportsService.submitActivityReport(report);
  }

  private submitPhotoDocument(document: PhotoDocumentLocal): Observable<PhotoDocument | PhotoDocumentLocal> {
    return this.photoDocumentsService.submitPhotoDocument(document);
  }

  private submitVacationRequest(request: VacationRequestLocal) {
    return this.vacationRequestsService.submitVacationRequest(request);
  }

  deleteEntry(reportId: number): void {
    this.notificationService.wait();
    defer(() => from(this.dbService.deleteOneFromDB(this.dbTable, reportId))).pipe(
      tap(() => this.entries = this.entries.filter(r => r.id !== reportId))
    ).subscribe(
      ()  => this.notificationService.close(),
      err => this.notificationService.alert(err)
    );
  }

  private get dbTable(): string {
    if (this.useCase === 'ar') return 'localReports';
    if (this.useCase === 'pr') return 'photoReports';
    if (this.useCase === 'pd') return 'localDocuments';
    if (this.useCase === 'vr') return 'localVacations';
    return '';
  }

  getReportName(): string {
    if (this.useCase === 'ar') return 'newReport';
    if (this.useCase === 'pr') return 'newReport';
    if (this.useCase === 'pd') return 'newDocument';
    if (this.useCase === 'vr') return 'newRequest';
    return 'create';
  }

  getEmptyEntriesTitle(): string {
    if (this.useCase === 'ar') return 'noSavedReports';
    if (this.useCase === 'pr') return 'noSavedReports';
    if (this.useCase === 'pd') return 'noPendingDocuments';
    if (this.useCase === 'vr') return 'noPendingVacations';
    return '';
  }

  callbackHandler(): void {
    if (this.useCase === 'ar') this.router.navigateByUrl(`time-tracking/assignment-select-${this.useCase}`);
    if (this.useCase === 'pr') this.router.navigateByUrl(`time-tracking/assignment-select-${this.useCase}`);
    if (this.useCase === 'pd') this.router.navigateByUrl('time-tracking/document-select');
    if (this.useCase === 'vr') this.router.navigateByUrl('time-tracking/vacation-type-select');
  }

}
