import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SalaryReport } from '@shared/factories';

import { PagingModel } from '@shared/models';
import { NotificationService, SalaryReportsService, SessionStorageService } from '@shared/services';

@Component({
  selector:      'salary-overview',
  templateUrl: './salary-overview.component.html',
  host: { class: 'height-full du-flex-column gap-xs overflow-auto' }
})
export class SalaryOverviewComponent implements OnInit {
  @Output() loadEntries = new EventEmitter<Function>;
  page:     number;
  paging:   PagingModel;
  reports:  SalaryReport[] = [];
  constructor(
    private salaryReportsService:  SalaryReportsService,
    private notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.loadEntries.emit(this.loadReports.bind(this));
    this.sessionStorageService.paging.subscribe(paging => this.paging = paging);
    this.loadReports();
  }

  loadReports(reload: boolean = null): void {
    if (!this.paging || !this.paging.total_pages || !this.page || this.page < this.paging.total_pages || reload) {
      this.page = reload || !this.page ? 1 : this.page+1;
      this.notificationService.wait();
      this.salaryReportsService.loadSalaryReports(this.page).subscribe(
        reports => {
          if (reload) this.reports = [...reports];
          else this.reports = [...this.reports, ...reports];
          this.sessionStorageService.setCachedEntries(this.reports);
          this.notificationService.close();
        },
        err => this.notificationService.alert(err),
        () => this.sessionStorageService.setPulltoRefreshState(true)
      );
    }
  }

}
