import { Component, OnInit } from '@angular/core';
import { Location          } from '@angular/common';
import { Observable, from, map, of, switchMap, take, tap } from 'rxjs';

import { Network } from '@capacitor/network';

import { User } from '@shared/factories';
import { Country, UserModel } from '@shared/models';
import { DBService, HelperService, ImageService, NotificationService, SessionStorageService, UserService } from '@shared/services';

import { Countries } from '@assets/countries.data';

@Component({
  selector:      'mileage-config',
  templateUrl: './mileage-config.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify' },
})
export class MileageConfigComponent implements OnInit {
  user:                User;

  licensePlate:        string;
  licensePlateCountry: string;
  signature:           string;
  countries:           Country[] = Countries;
  constructor(
    private location:              Location,
    private userService:           UserService,
    private imageService:          ImageService,
    private helperService:         HelperService,
    private dbService:             DBService,
    private notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.notificationService.wait();
    this.sessionStorageService.setHeaderTitle('mileageOverview');
    this.sessionStorageService.setHeaderControls({ left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.location.back() }] });
    this.sessionStorageService.setProgressBar(null);
    this.userService.currentUser.pipe(
      take(1),
      switchMap(user => user.signature ? of(user) : this.saveSignature(user))
    ).subscribe(
      user => {
        this.user                = user;
        if (!this.user.address) this.user.address = {
          street: '-',
          zip:    '-',
          city:   '-'
        };
        this.signature           = user.signature;
        this.licensePlate        = user.mileageConfig && user.mileageConfig.licensePlate || '';
        this.licensePlateCountry = user.mileageConfig && user.mileageConfig.country      || 'DE';

        this.notificationService.close();
      },
      err => this.notificationService.alert(err)
    );
  }

  changesDetected(): boolean {
    if (this.user.mileageConfig && this.user.mileageConfig.licensePlate !== this.licensePlate)        return true;
    if (this.user.mileageConfig && this.user.mileageConfig.country      !== this.licensePlateCountry) return true;
    if (this.user.signature !== this.signature) return true;
    return false;
  }

  openSignaturePad(): void {
    this.sessionStorageService.pushDynamicComponent({
      component: 'SignaturePad',
      props: {
        signature: this.signature,
        callback: (signature: string) => this.signature = signature
      }
    });
  }

  private saveSignature(user: User): Observable<User> {
    if (user && user.mileageConfig && user.mileageConfig.signaturePath) return from(Network.getStatus()).pipe(
      switchMap((res: any) => {
        if (res.connected) return this.imageService.loadImage(user.mileageConfig.signaturePath);
        else return from(this.dbService.loadOneFromDB('users')).pipe(map((res: UserModel) => res.signature));
      }),
      map(signature => new User(Object.assign(user.toJSON(), { signature }))),
      tap(user => this.userService.setUser(user))
    );
    return of(user);
  }

  changeLicencePlate(): void {
		this.notificationService.wait();
    let params = {
      user_license_plate: {
        license_plate: this.licensePlate.trim(),
        country: this.licensePlateCountry,
        signature: this.signature
      }
    };
    this.helperService.changeLicensePlate(params).subscribe(
      () => {
        if (!this.user.mileageConfig) this.user.mileageConfig = {};
        this.user.mileageConfig.licensePlate = this.licensePlate.trim();
        this.user.mileageConfig.licensePlateCountry = this.licensePlateCountry.trim();
        this.user.signature = this.signature;

        this.userService.setUser(this.user);
        this.notificationService.close();
      },
      err => this.notificationService.alert(err)
    );

  }

}
