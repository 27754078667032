<ng-container *ngIf="daily">
  <div class="du-flex-justify du-flex-align bb-grey">
    <span class="absolute left-70 right-70 du-flex-center color-grey bold" translate="dailyReport"></span>
    <img class="pl-xl pr-xl pt-m pb-m mla " src="assets/svg/close.svg" (click)="close()">
  </div>

  <assignment-picker [assignment]="daily && daily.assignment" (selectCallback)="adjustAssignment($event)"></assignment-picker>
  <span class="pl-xl pr-xl pt-s color-red lh-l" *ngIf="daily.assignment && !daily.assignment.confirmed" translate="confirmEbs"></span>

  <div class="du-flex-1 du-flex-column gap-xl p-xl overflow">
    <div class="du-flex-1 du-flex-column gap-xl overflow-auto" [ngClass]="!daily.assignment || !daily.assignment.confirmed ? 'opacity-5 event-none' : ''">
      <div class="du-flex-column gap-xs">
        <span class="bold" translate="date"></span>
        <input type="date" class="date-input white" [class.b-red]="daily.dateErrors.size" [ngModel]="date | date:'yyyy-MM-dd'" (ngModelChange)="adjustDates($event)">
        <span class="color-red text-xs" *ngFor="let err of daily.dateErrors">{{ err | translate }}</span>
      </div>

      <constructor-daily [daily]="daily" (adjustDaily)="adjustDaily($event)" (validate)="validate()"></constructor-daily>
    </div>

    <button class="btn-primary btn-round du-flex-auto" translate="save" [disabled]="!daily.assignment || !daily.assignment.confirmed || daily.techErrorsAll.size" (click)="save()"></button>
  </div>
</ng-container>