import { DailyLocalExtendedModel, DailyLocalModel, DailyModel, DailyOverviewModel, DailyToSubmitModel, PauseModel } from "@shared/models";
import { Holiday, PauseLocal, PauseExtended, PauseBasic, Assignment, ErrorsHandlerMixin } from "@shared/factories";

export class DailyBasic {
  id:       number;
  startsAt: Date;
  endsAt:   Date;
  project:  string;

  pauses:   PauseBasic[];
  holidays: Holiday[];
  constructor(data: DailyModel) {
    this.id       = data.id;
    this.startsAt = this.parceDate(data.started_at);
    this.endsAt   = this.parceDate(data.ended_at);
    this.project  = data.project;
  
    this.pauses   = data.pauses   && data.pauses.length   ? data.pauses.map(p => new PauseBasic(p)) : [];
    this.holidays = data.holidays && data.holidays.length ? data.holidays.map(h => new Holiday(h))  : [];
  }

  private parceDate(date: any) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  toJSON(): DailyModel {
    let obj = {
      started_at: this.startsAt ? this.startsAt.toISOString()        : null,
      ended_at:   this.endsAt   ? this.endsAt.toISOString()          : null,
      project:    this.project  ? this.project                       : null,

      pauses:     this.pauses   ? this.pauses.map(p => p.toJSON())   : null,
      holidays:   this.holidays ? this.holidays.map(h => h.toJSON()) : null
    };
    if (this.id) obj = Object.assign(obj, { id: this.id });
    return obj;
  }

  get durationWithPauses():     number { return this.endsAt.getTime() - this.startsAt.getTime();            }
  get durationExludingPauses(): number { return Math.max(this.durationWithPauses - this.pausesDuration, 0); }
  get pausesDuration():         number { return this.pauses.reduce((sum, p) => sum + p.duration, 0);        }
}

export class DailyLocal extends ErrorsHandlerMixin(DailyBasic) {
  pauses:      PauseLocal[];
  mileage:     boolean;
  placeholder: boolean;
  constructor(data: DailyLocalModel) {
    super(data);

    this.childrenName = 'pauses';
    this.pauses       = data.pauses && data.pauses.length ? data.pauses.map(p => new PauseLocal(p)) : [];
    this.mileage      = data.mileage;
    this.placeholder  = data.placeholder;
  }

  toJSON(): DailyLocalModel {
    return Object.assign(super.toJSON(), {
      pauses:  this.pauses  ? this.pauses.map(p => p.toJSON()) : null,
      mileage: this.mileage ? this.mileage                     : null
    });
  }

  toSubmitData(): DailyToSubmitModel {
    return {
      activity:   this.project,
      started_at: this.startsAt instanceof Date ? this.startsAt.toISOString() : null,
      ended_at:   this.endsAt   instanceof Date ? this.endsAt.toISOString()   : null,
      pauses:     this.pausesToJSON(this.sortedPauses)
    };
  }

  pausesToJSON(pauses: PauseLocal[]): PauseModel[] {
    return pauses.map((p, index) => {
      p.id = index + 1;
      return p.toJSON();
    });
  }

  get lastPause():     PauseLocal { return this.sortedPauses.pop(); }
  get latestPauseId(): number     { return this.pauses && this.pauses.length ? this.pauses.sort((a,b) => b.id - a.id)[0].id+1 : 1; }

  get sortedPauses(): PauseLocal[] {
    let p = [...this.pauses];

    p.sort((a, b) => {
      if      (a.start < b.start) return -1;
      else if (a.start > b.start) return 1;
      else return 0;
    });

    return p;
  }

  get pauseTechErrors():  Set<string> { return super.childrenTechErrors;  }
  get pauseLegalErrors(): Set<string> { return super.childrenLegalErrors; }

}

export class DailyLocalExtended extends DailyLocal {
  userId:     number;
  assignment: Assignment;
  notes:      string;

  pauses:     PauseLocal[];
  constructor(data: DailyLocalExtendedModel) {
    super(data);

    this.userId     = data.userId;
    this.assignment = data.assignment ? new Assignment(data.assignment) : null;
    this.notes      = data.notes;

    this.pauses     = data.pauses && data.pauses.length ? data.pauses.map(p => new PauseLocal(p)) : null;
  }

  toJSON(): DailyLocalExtendedModel {
    return Object.assign(super.toJSON(), {
      userId:     this.userId     ? this.userId                      : null,
      assignment: this.assignment ? this.assignment.toJSON()         : null,
      notes:      this.notes      ? this.notes                       : null,
      pauses:     this.pauses     ? this.pauses.map(p => p.toJSON()) : null
    });
  }

}

export class DailyOverview extends DailyBasic {
  originalTimeFrameId: number;
  corrected:           boolean;
  pauses:              PauseExtended[];

  collapsed:           boolean;
  constructor(data: DailyOverviewModel) {
    super(data);
    this.originalTimeFrameId = data.original_time_frame_id;
    this.corrected           = data.corrected;
    this.pauses              = data.pauses && data.pauses.length ? data.pauses.map(p => new PauseExtended(p)) : null;

    this.collapsed            = null;
  }

  toJSON(): DailyOverviewModel {
    return Object.assign(super.toJSON(), {
      original_time_frame_id: this.originalTimeFrameId ? this.originalTimeFrameId         : null,
      corrected:              this.corrected           ? this.corrected                   : null,
      pauses:                 this.pauses              ? this.pauses.map(p => p.toJSON()) : null
    });
  }

}
