import { ZipCodeModel } from "@shared/models";

export class ZipCode {
  name:        string;
  countryISO: string;
  stateISO:   string;
  zipCode:    string;
  constructor(data: ZipCodeModel) {
    this.name       = data.name;
    this.countryISO = data.country_iso;
    this.stateISO   = data.state_iso;
    this.zipCode    = data.zip_code;
  }

  toJSON(): ZipCodeModel {
    return {
      name:        this.name       ? this.name       : null,
      country_iso: this.countryISO ? this.countryISO : null,
      state_iso:   this.stateISO   ? this.stateISO   : null,
      zip_code:    this.zipCode    ? this.zipCode    : null
    };
  }

}
