import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { slideUp   } from '@shared/animations';
import { SortModel } from '@shared/models';
import { SessionStorageService } from '@shared/services';

@Component({
  selector:      'sort',
  templateUrl: './sort.component.html',
  host: { class: 'absolute bottom-0 left-0 right-0 du-flex-column btl-radius btr-radius bg-white' },
  animations: [ slideUp ]
})
export class SortComponent implements OnInit, OnDestroy {
  @Input() label:   string;
  @Input() entries: SortModel[];
  @Output() save = new EventEmitter<void>;
  private readonly COMPONENT_NAME: string = 'Sort';
  @HostBinding('@slideUp') get slideIn() { return 'open'; }
  constructor( private sessionStorageService: SessionStorageService ) {}

  ngOnInit(): void {
    this.sessionStorageService.pushOverflowStack(this.COMPONENT_NAME);
    this.sessionStorageService.sortFlow.subscribe(sort => {
      if (sort) {
        let active = this.entries.find(e => e.column === sort.column && e.dir === sort.dir);
        if (active) active.active = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.sessionStorageService.popOverflowStack(this.COMPONENT_NAME);
  }

  close(): void {
    this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME);
  }

  sortBy(sort: SortModel = null) {
    this.entries.forEach(e => e.active = false);
    sort.active = true;
  }

  applySort(): void {
    this.saveSort();
    this.save.emit();
    this.close();
  }

  private saveSort(): void {
   let active = this.entries.find(e => e.active);
   this.sessionStorageService.setSortFlow({
     column: active.column,
     label:  active.label,
     dir:    active.dir
   });
  }

}
