import { Injectable } from '@angular/core';
import { Router     } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { from, of       } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { MileageReportLocal, MileageReportPrefill, StandaloneMileageReportOverview } from '@shared/factories';
import { MetaModel, MileageReportPrefillModel, StandaloneMileageReportModel } from '@shared/models';
import { QueryCollectorService, SessionStorageService, DBService } from '@shared/services';

import { environment } from 'environments/environment';

export interface StandalonePrefilledMileageReportsResponseModel {
  standalone_mileage_report: MileageReportPrefillModel;
}

export interface StandaloneMileageReportResponseModel {
  standalone_mileage_report: StandaloneMileageReportModel;
}

export interface StandaloneMileageReportsResponseModel {
  standalone_mileage_reports: StandaloneMileageReportModel[];
  meta:                       MetaModel;
}

@Injectable({
  providedIn: 'root'
})
export class MileageReportsService {
  private MILEAGE_REPORTS_API:     string = `${environment.apiUrl}/api/mobile/v3/standalone_mileage_reports`;
  private MR_DB_TABLE:             string = 'photoDocuments';
  private LOCAL_MR_DB_TABLE:       string = 'localMileage';
  private PENDING_UPLOAD_DB_TABLE: string = 'pendingUpload';

  constructor(
    private router:                Router,
    private http:                  HttpClient,
    private dbService:             DBService,
    private sessionStorageService: SessionStorageService,
    private queryCollectorService: QueryCollectorService,
  ) { }

  loadMileageReports(page: number = 1): Observable<StandaloneMileageReportOverview[]> {
    let offline = this.sessionStorageService.offlineModeValue;
    if (offline) return this.loadMileageReportsFromDB(page);
    else         return this.requestMileageReports(page);
  }

  private loadMileageReportsFromDB(page: number = 1): Observable<StandaloneMileageReportOverview[]> {
    let { filter, sort } = this.queryCollectorService.parceVacationRequestDBQuery(page);
    return from(this.dbService.loadMultipleFromDBPaginated(this.MR_DB_TABLE, page, filter, sort)).pipe(
      take(1),
      map(res => {
        this.sessionStorageService.setPaging(res.paging);
        return res.data.map((v: StandaloneMileageReportModel) => new StandaloneMileageReportOverview(v));
      })
    );
  }

  private requestMileageReports(page: number = 1): Observable<StandaloneMileageReportOverview[]> {
    return this.http.get<StandaloneMileageReportsResponseModel>(`${this.MILEAGE_REPORTS_API}${this.queryCollectorService.getMileageRequestsQuery(page)}`).pipe(
      take(1),
      tap(res => this.sessionStorageService.setPaging(res.meta.paging)),
      map(res => res.standalone_mileage_reports.map(m => new StandaloneMileageReportOverview(m))),
      tap(res => this.dbService.saveMultipleToDB(this.MR_DB_TABLE, res))
    );
  }

  requestPrebuildMileageReport(startDate: Date, endDate: Date, assignmentId: number): Observable<MileageReportPrefill> {
    return this.http.get<StandalonePrefilledMileageReportsResponseModel>(`${this.MILEAGE_REPORTS_API}/new${this.queryCollectorService.getPrebuildMileageRequestsQuery(startDate, endDate, assignmentId)}`).pipe(
      map(res => res?.standalone_mileage_report),
      map(res => new MileageReportPrefill(res))
    );
  }

  submitMileageReport(mileage: MileageReportLocal): Observable<StandaloneMileageReportOverview | MileageReportLocal> {
    let offline = this.sessionStorageService.offlineModeValue;
    if (offline) return this.pushReportToPendingList(mileage);
    else return this.postLocalMileageReport(mileage);
  }

  submitPendingPhotoDocument(mileage: MileageReportLocal): Observable<StandaloneMileageReportOverview> {
    let offline = this.sessionStorageService.offlineModeValue;
    if (!offline) return this.postPendingMileageReport(mileage);
    return of(null);
  }

  private postLocalMileageReport(mileage: MileageReportLocal): Observable<StandaloneMileageReportOverview> {
    return this.postMileageReport(mileage).pipe(
      tap(()  => { if (mileage.id) from(this.dbService.deleteOneFromDB(this.LOCAL_MR_DB_TABLE, mileage.id));  }),
      tap(()  => this.router.navigate(['time-tracking/info'], { queryParams: { type: 'submit', useCase: 'mm' }}))
    );
  }

  private postMileageReport(mileage: MileageReportLocal): Observable<StandaloneMileageReportOverview> {
    let data = { standalone_mileage_report: mileage.toSubmitJSON() };
    return this.http.post<StandaloneMileageReportResponseModel>(this.MILEAGE_REPORTS_API, data).pipe(
      map(res => new StandaloneMileageReportOverview(res.standalone_mileage_report))
    );
  }

  private postPendingMileageReport(mileage: MileageReportLocal): Observable<StandaloneMileageReportOverview> {
    return this.postMileageReport(mileage).pipe(
      tap(() => { if (mileage.notSynced) from(this.dbService.deleteOneFromDB(this.PENDING_UPLOAD_DB_TABLE, mileage.id)); }),
    );
  }

  private pushReportToPendingList(mileage: MileageReportLocal): Observable<MileageReportLocal> {
    return from(this.dbService.saveOneToDB(this.PENDING_UPLOAD_DB_TABLE, Object.assign(document, { useCase: 'mm', notSynced: true }))).pipe(
      tap(() => { if (mileage.id) from(this.dbService.deleteOneFromDB(this.LOCAL_MR_DB_TABLE, mileage.id));  }),
      tap(() => this.router.navigate(['time-tracking/info'], { queryParams: { type: 'offline', useCase: 'mm' }})),
      map(() => mileage)
    );
  }

}
