import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { PhotoDocumentLocal } from '@shared/factories';
import { SessionStorageService, NotificationService } from '@shared/services';

@Component({
  selector:      'document-preselect-item',
  templateUrl: './document-preselect-item.component.html',
  host: { class: 'du-flex-column shadow' }
})
export class DocumentPreselectItemComponent {
  @Input() document: PhotoDocumentLocal;
  @Output() submitDocument = new EventEmitter<PhotoDocumentLocal>;
  @Output() deleteDocument = new EventEmitter<number>;
  collapsed:   boolean;
  extendImage: boolean;
  constructor(
    private router:                Router,
    private sessionStorageService: SessionStorageService,
    private notificationService:   NotificationService
  ) {}

  editDocument(): void {
    this.sessionStorageService.setTemporaryValue(this.document);
    this.router.navigateByUrl('time-tracking/document-select');
  }

  submitDocumentPrompt(): void {
    this.notificationService.confirm({
      title: 'submit',
      desc: 'Are you sure you want to submit this document?',
      rightFn: () => this.submitDocument.emit(this.document)
    });
  }

  deleteDocumentPrompt(): void {
    this.notificationService.confirm({
      title: 'delete',
      desc: 'Are you sure you want to delete this document?',
      buttons: {
        left:  'yes',
        right: 'no',
      },
      leftFn: () => this.deleteDocument.emit(this.document.id)
    });
  }

}
