import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map, take, tap } from 'rxjs/operators';

import { EBS       } from '@shared/factories';
import { EBSModel  } from '@shared/models';
import { DBService } from './db.service'

import { environment } from 'environments/environment';

interface EBSResponce {
  ebs_data: EBSModel[];
}

@Injectable({
  providedIn: 'root'
})
export class EbsService {
  private EBS_API: string = `${environment.apiUrl}/api/mobile/v3/ebs_data`;

  constructor(
    private http:      HttpClient,
    private DBService: DBService
  ) { }

  confirmEBS(ebsId: number): Observable<any> {
    return this.http.put<any>(`${this.EBS_API}/${ebsId}/confirm`, {}).pipe(take(1));
  }

  private loadEBS(): Observable<EBS[]> {
    return this.http.get<EBSResponce>(`${this.EBS_API}?q[ebs_data][latest]=true&per_page=250`).pipe(
      take(1),
      map(res => res.ebs_data),
      map(ebs_data => ebs_data.map(e => new EBS(e)))
    );
  }

  syncEBS(): Observable<EBS[]> {
    return this.loadEBS().pipe(tap(res => this.DBService.saveMultipleToDB('ebs', res)));
  }

}
