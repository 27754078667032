<div class="du-flex-column du-flex-1 gap-m overflow">
  <span class="text-l bold" translate="selectAssignment"></span>
  <div class="relative width-full mb-s">
    <input type="text" class="placeholder-primary-grey pl45-i" [(ngModel)]="search" placeholder="Search">
    <span class="du-icon-search font-icon absolute-top-center ml-m"></span>
  </div>
  <ul class="du-flex-column gap-m overflow-auto" *ngIf="assignments && assignments.length">
    <li *ngFor="let assignment of assignments | search:'title':search" (click)="selectAssignment(assignment)"
         class="du-flex-justify du-flex-align du-flex-max-content lh-l b-radius p-m hover-active"
        [class.bg-card-grey]="!tempAssignment || tempAssignment.id !== assignment.id"
        [class.bg-blue]="tempAssignment && tempAssignment.id === assignment.id"
        [class.color-white]="tempAssignment && tempAssignment.id === assignment.id">
      <span>{{ assignment.title }}</span>
      <span class="width-35 text-center bg-orange b-radius color-white pl-s pr-s" [style.opacity]="+!!assignment.localDailies">{{ assignment.localDailies }}</span>
    </li>
  </ul>
  <span class="ma" *ngIf="!assignments || !assignments.length" translate="noActiveAssignments"></span>
</div>

<div class="du-flex-column du-flex-justify-end gap-s mt-l">
  <div class="du-flex-column bg-light-orange b-radius p-xs" *ngIf="!closeHolidaysTips && tempAssignment && tempAssignment.holidays && tempAssignment.holidays.length"
      [@collapse]="{ value: ':enter', params: { offsetTop: '-12px' }}"
      [@.disabled]="!animate">
    <div class="du-flex-align-start du-flex-justify gap-xs overflow">
      <span class="du-icon-holiday color-orange font-icon"></span>
      <span class="du-flex-column du-flex-1 gap-xxs font-s lh-l">
        <span translate="includesPublicHolidays"></span>
        <span class="du-flex-align gap-xs" *ngFor="let holiday of tempAssignment.holidays">
          <span class="du-flex-center circle-xxs bg-orange"></span>
          <span>{{ holiday.name }}</span>
        </span>
      </span>
      <span class="du-icon-close font-icon-small" (click)="animate = true; closeHolidaysTips = true"></span>
    </div>
  </div>

  <ng-container *ngIf="useCase === 'ar'">
    <div class="du-flex-column bg-light-orange b-radius p-xs" *ngIf="!closeDailiesTip && assignmentReady() && tempAssignment.localDailies"
        [@collapse]="{ value: ':enter', params: { offsetTop: '-12px' }}"
        [@.disabled]="!animate">
      <div class="du-flex-align-start du-flex-justify gap-xs overflow">
        <span class="du-icon-combine font-icon"></span>
        <span class="font-s lh-l du-flex-1" translate="dailiesAvailable"></span>
        <span class="du-icon-close font-icon-small" (click)="animate = true; closeDailiesTip = true"></span>
      </div>
    </div>

    <div class="du-flex-column bg-light-blue b-radius p-xs" *ngIf="!closeMileageTip && assignmentReady() && tempAssignment.mileageData"
        [@collapse]="{ value: ':enter', params: { offsetTop: '-12px' }}"
        [@.disabled]="!animate">
      <div class="du-flex-align-start du-flex-justify gap-xs overflow">
        <span class="du-icon-mileage color-blue font-icon"></span>
        <span class="font-s lh-l du-flex-1" translate="millageAppliedToAssignment"></span>
        <span class="du-icon-close font-icon-small" (click)="animate = true; closeMileageTip = true"></span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="useCase !== 'vr'">
    <div class="du-flex-column bg-light-blue b-radius p-s" *ngIf="mileageNotConfigured()"
        [@collapse]="{ value: ':enter', params: { offsetTop: '-12px' }}"
        [@.disabled]="!animate">
      <div class="du-flex-column gap-xs overflow">
        <div class="du-flex-align-start du-flex-justify gap-xs">
          <span class="du-icon-mileage color-blue font-icon"></span>
          <span class="du-flex-column font-s lh-l du-flex-1" translate="configureMileage"></span>
        </div>
        <span class="mla color-blue pb-xxs" translate="apply" (click)="openMileageConfig()"></span>
      </div>
    </div>

    <div class="du-flex-column bg-light-orange b-radius p-s" *ngIf="tempAssignment && !tempAssignment.confirmed"
        [@collapse]="{ value: ':enter', params: { offsetTop: '-12px' }}"
        [@.disabled]="!animate">
      <div class="du-flex-column gap-xs overflow">
        <div class="du-flex-align-start du-flex-justify gap-xs">
          <span class="du-icon-warning color-orange font-icon"></span>
          <span class="du-flex-column font-s lh-l du-flex-1" translate="confirmEbs"></span>
        </div>
        <span class="du-flex-align gap-xxs pb-xxs mla" (click)="confirmEBS()">
          <span class="color-blue" translate="ebs"></span>
          <span class="du-icon-arrow-right color-blue font-icon"></span>
        </span>
      </div>
    </div>
  </ng-container>

  <div class="du-flex-column bg-light-orange b-radius p-xs" *ngIf="useCase === 'pr'">
    <div class="du-flex-align-start du-flex-justify gap-xs overflow">
      <span class="du-icon-warning color-orange font-icon"></span>
      <span class="font-s lh-l du-flex-1" translate="doNotUseUploadDocuments"></span>
    </div>
  </div>

  <button class="width-full btn-primary btn-round mt-xs" translate="next" (click)="confirmAssignment()" [disabled]="!assignmentReady()"></button>
</div>
