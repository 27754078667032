import { Injectable   } from '@angular/core';
import { HttpClient   } from '@angular/common/http';
import { forkJoin, of } from 'rxjs';
import { Observable   } from 'rxjs/internal/Observable';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { UserService, ImageService, DBService } from '@shared/services';
import { Banner      } from '@shared/factories';
import { BannerModel } from '@shared/models';

import { environment } from 'environments/environment';

interface CampaignBannersResponse { app_images:      BannerModel[]; }
interface FeatureBannersResponse  { company_banners: BannerModel[]; }

@Injectable({
  providedIn: 'root'
})
export class BannerService {
  private CAMPAIGN_API:        string = `${environment.apiUrl}/api/mobile/v3/app_images`;
  private COMPANY_BANNERS_API: string = `${environment.apiUrl}/api/mobile/v3/company_banners`;

  constructor(
    private http:         HttpClient,
    private DBService:    DBService,
    private imageService: ImageService,
    private userService:  UserService,
  ) { }

  private loadCampaignBanner(): Observable<any> {
    return this.http.get<CampaignBannersResponse>(this.CAMPAIGN_API, { observe: 'response' }).pipe(
      take(1),
      switchMap(res => forkJoin(
        of(res.body.app_images[0]),
        of(res.headers.get('etag')),
        this.imageService.loadImage(`${environment.apiUrl}${res.body.app_images[0].image_path}`)
      )),
      map((res: any) => new Banner(Object.assign(res[0], {
        id:  'Campaign_' + this.userService.currentUserValue.id,
        tag: 'campaignBanner'
      }), res[1], res[2])),
      catchError(err => {
        console.error(err);
        return of(null);
      })
    );
  }

  private loadFeatureBanners(): Observable<Banner[]> {
    return this.http.get<FeatureBannersResponse>(this.COMPANY_BANNERS_API, { observe: 'response' }).pipe(
      take(1),
      switchMap(res => forkJoin(res.body.company_banners.map(banner => this.imageService.loadImage(`${environment.apiUrl}${banner.image_path}`).pipe(
        map(image => {
          banner.image = image as string;
          banner.etag  = res.headers.get('etag');
          return banner;
        })
      )))),
      map((banners: BannerModel[]) => banners.map(b => new Banner(Object.assign(b, { id: `${b.tag}_${this.userService.currentUserValue.id}` })))),
      catchError(err => {
        console.error(err);
        return of(null);
      })
    );
  }

  syncBanners(): Observable<Banner[]> {
    return forkJoin([
      this.loadCampaignBanner(),
      this.loadFeatureBanners()
    ]).pipe(
      map(res => res[0] && res[1] ? [res[0], ...res[1]] : null),
      tap(res => res ? this.DBService.saveMultipleToDB('banners', res) : null)
    );
  }

}
