<div class="du-flex-column du-flex-1 overflow-auto" *ngIf="user">
  <div class="du-flex-column gap-xl p-xl bb-grey">
    <div class="du-flex-column gap-xs">
      <span class="font-l bold" translate="licensePlate"></span>
      <span translate="enterLicensePlate"></span>
      <span translate="licensePlateEx"></span>
    </div>

    <div class="du-flex-column gap-m">
      <span class="bold" translate="country"></span>
      <select [(ngModel)]="licensePlateCountry">
        <option value="" disabled selected>Select Country</option>
        <option *ngFor="let country of countries" [value]="country.code">{{country.name}}</option>
      </select>
    </div>

    <div class="du-flex-column gap-m">
      <span class="bold" translate="licensePlate"></span>
      <input type="text" [(ngModel)]="licensePlate">
    </div>
  </div>

  <div class="du-flex-column gap-xl pt-m pl-xl pr-xl pb-xl">
    <div class="du-flex-column gap-m">
      <div class="du-flex-column gap-xs">
        <span class="font-l bold" translate="departurePoint"></span>
        <span translate="changeDepatrurePoint"></span>
      </div>
      <span class="bold" translate="streetName"></span>
      <input type="text" [(ngModel)]="user.address.street" disabled="true">
    </div>

    <div class="du-flex-justify gap-xl">
      <div class="du-flex-column gap-m">
        <span class="bold" translate="postalCode"></span>
        <input type="text" [(ngModel)]="user.address.zip" disabled="true">
      </div>
      <div class="du-flex-column gap-m">
        <span class="bold" translate="city"></span>
        <input type="text" [(ngModel)]="user.address.city" disabled="true">
      </div>
    </div>

    <div class="du-flex-align b-grey gap-xs p-m shadow-header b-radius" (click)="openSignaturePad()" *ngIf="!signature">
      <img src="assets/svg/signature.svg">
      <span translate="signature"></span>
      <div class="du-flex-align gap-s mla">
        <span class="color-blue bold" translate="sign" *ngIf="!signature"></span>
      </div>
    </div>

    <div class="du-flex-column gap-s" *ngIf="signature" (click)="openSignaturePad()">
      <img class="b-grey" [src]="signature">
      <span class="lh-l" translate="withMySignatureConfirm"></span>
    </div>
  </div>
</div>

<div class="p-xl" *ngIf="user">
  <button class="width-full btn-primary btn-round" translate="submit" (click)="changeLicencePlate()" [disabled]="!licensePlate || !licensePlateCountry || !signature || !changesDetected()"></button>
</div>
