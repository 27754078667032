<div class="du-flex-justify du-flex-align pl-m pt-m pr-m pb-s gap-xl bb-grey" *ngIf="index >= 0">
  <span class="du-flex-align gap-xxs font-l bold">
    <span>{{ workDay.date | date: 'EEE' }}</span>
    <span>{{ workDay.date | date: '- dd.MM.YYYY' }}</span>
  </span>
  <img *ngIf="!workDay.placeholder" src="assets/svg/close.svg" class="pl10 pr10" (click)="resetPlaceholder()">
</div>

<div class="du-flex-column gap-s pl-m pt-m pr-m pb-s">
  <span class="font-s" translate="placeOfDeparture"></span>
  <input type="text" class="width-full" [(ngModel)]="workDay.externalEmployeeAddress">

  <span class="font-s" translate="destination"></span>
  <input type="text" class="width-full" [(ngModel)]="workDay.assignmentAddress">

  <span class="font-s" translate="amountOfKm"></span>
  <input type="number" min="1" max="999" pattern="[0-9]+" class="width-full" [(ngModel)]="workDay.amountOfKm" (blur)="validateDistance(workDay)">

  <div class="du-flex-column" *ngIf="workDay.techErrors && workDay.techErrors.size" @collapse>
    <div class="du-flex-column gap5 text-xs overflow">
      <span class="color-red" *ngFor="let err of workDay.techErrors"  @collapse><span class="overflow">{{ err | translate}}</span></span>
    </div>
  </div>
</div>

<div class="absolute-full du-flex-center b-radius bg-card-grey-light" @fadeIn *ngIf="workDay.placeholder" (click)="addDefaultDay()">
  <div class="du-flex-center width-full">
    <div class="du-flex-center circle-75 bg-blue-gradient">
      <img src="assets/svg/plus-white.svg">
    </div>
  </div>
</div>
